import React, { useMemo, useState } from 'react'
import SendNotificationSection from './Tabs/SendNotificationSection'
import TabsSelector, { Tab } from '../../../components/TabsSelector/TabsSelector'
import CustomersRecallSettings from './Tabs/CustomersRecallSettings'

const pageTabs: Tab[] = [
    {
        value: 'send_notification',
        label: 'Invia notifica',
    },
    {
        value: 'recall_customers',
        label: 'Recall clienti',
    },
]

const NotificationsPage: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState<{ value: string; label: string }>(pageTabs[0])

    const renderCurrentTab = useMemo(() => {
        switch (selectedTab.value) {
            case 'send_notification':
                return <SendNotificationSection />
            case 'recall_customers':
            default:
                return <CustomersRecallSettings />
        }
    }, [selectedTab])

    return (
        <>
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">Gestione notifiche</span>
                </div>
                <div className="page-header-section__right-box" />
            </div>
            <TabsSelector
                tabs={pageTabs}
                activeTabValue={selectedTab.value}
                onChangeTab={(newTab) => setSelectedTab(newTab)}
            />
            {renderCurrentTab}
        </>
    )
}

export default NotificationsPage
