/* eslint-disable indent */
/* eslint-disable no-mixed-operators */
/* eslint-disable nonblock-statement-body-position */
import React, { useEffect, useMemo, useState } from 'react'
import TextInput from '../../../components/Inputs/TextInput'
import SingleSelect from '../../../components/Inputs/SingleSelect'
import Button from '../../../components/Buttons/Button'
import {
    BodyPart,
    BodySectionMeasure,
    EntityStatus,
    InfoItem,
    MediaFilesEntity,
    Protocol,
    ResultMedia,
    ResultMediaToCreate,
    ResultToCreate,
    SimpleEntity,
} from '../../../types/data'
import { ToastError, ToastSuccess } from '../../../utility/toast'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../resources/routes-constants'
import MultiSelect from '../../../components/Inputs/MultiSelect'
import { useAppSelector } from '../../../store/reducers/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import UppercaseLabel from '../../../components/Typography/UppercaseLabel'
import ResultMediaSection from '../../../custom-components/ResultMediaSection'
import FixedMeasureInput from '../../../components/Inputs/FixedMeasureInput'
import { formatItemForSelectOptions, formatItemsForSelectOptions } from '../../../utility/functions'
import {
    addMediaFile,
    createResultMedia,
    createSingleResult,
    getResultBodyParts,
    getSingleProtocol,
} from '../../../resources/api-constants'
import Datepicker from '../../../components/DatePicker/Datepicker'
import ImportProtocolModal from '../../../components/Modal/ImportProtocolModal'
import TextareaInput from '../../../components/Inputs/TextareaInput'
import { cloneDeep } from 'lodash'
import AsyncSingleSelect from '../../../components/Inputs/AsyncSingleSelect'

const pregnancyOptions = [
    {
        value: '1',
        label: 'No',
    },
    {
        value: '2',
        label: 'Si',
    },
    {
        value: '3',
        label: 'In corso',
    },
]

const smokeOptions = [
    {
        value: 'no',
        label: 'No',
    },
    {
        value: 'yes',
        label: 'Si',
    },
]

const hydrationOptions = [
    {
        value: '<1L',
        label: '<1L',
    },
    {
        value: '>1L<2L',
        label: '>1L<2L',
    },
    {
        value: '>2L',
        label: '>2L',
    },
]

const defaultResult: ResultToCreate = {
    startDate: '',
    endDate: '',
    idCenter: -1,
    status: EntityStatus.PENDING,
    evaluation: null,
    idPriorityIntensity: '',
    priorityDescription: '',
    resultDescription: '',
    notes: '',
    bodyAreas: [],
    goals: [],
    priorities: [],
    idApplicationType: -1,
    protocols: [],
    idLifeMoment: null,
    idPhisicalActivity: '-1',
    skinTypes: [],
    idCustomerGender: '-1',
    customerAge: '',
    devices: [],
    idFrequency: '-1',
    sessionsNumber: '',
    bodySectionMeasures: [],
    customerHydration: null,
    customerPregnancy: null,
    customerPregnancyNumber: null,
    customerProfession: null,
    customerSunProtection: null,
    customerSkinCare: null,
    customerSmoke: null,
    customerWeight: null,
    idChildbirthType: null,
    idAlcoholConsumption: null,
    idHealtyDiet: null,
    idStressLevel: null,
    idBodyPartCover: null,
    customerCurrentTreatments: '',
    medicalGroups: [],
    medicalProblems: [],
}

const NewResultPage: React.FC = () => {
    const data = useAppSelector((data) => data.data)
    const user = useAppSelector((data) => data.user)
    const [showImportProtocolModal, setShowImportProtocolModal] = useState(false)
    const [result, setResult] = useState<ResultToCreate>(defaultResult)
    const [protocolsToImportIds, setProtocolsToImportIds] = useState<string[]>([])
    const [selectedGoalsIds, setSelectedGoalsIds] = useState<string[]>([])
    const [selectedMedicalGroups, setSelectedMedicalGroups] = useState<string[]>([])
    const [protocolsToImport, setProtocolsToImport] = useState<(Protocol | undefined)[]>([])
    const [resultBodyParts, setResultBodyParts] = useState<BodyPart[]>([])
    const [resultMediaToUpload, setResultMediaToUpload] = useState<any[][]>([[]])
    const [selectedCenterId, setSelectedCenterId] = useState(-1)
    const [isLoading, setIsLoading] = useState(false)
    const [resetSelectedCenter, setResetSelectedCenter] = useState(false)

    const navigate = useNavigate()

    const calculateBodyParts = async () => {
        try {
            const fetchedResultBodyParts = await getResultBodyParts(
                user.loggedUserData?.authToken || '',
                result.bodyAreas.join(',')
            )
            if (fetchedResultBodyParts) setResultBodyParts(fetchedResultBodyParts)
            setResultMediaToUpload(new Array<any[]>(fetchedResultBodyParts?.length ?? 0).fill([]))
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (result.bodyAreas && result.bodyAreas.length > 0) {
            void calculateBodyParts()
            const defaultMeasures = data.bodySections.map((bodySection) => ({
                idBodySection: bodySection.id,
                measure: '',
            }))
            if (result.bodyAreas.includes('2'))
                setResult({
                    ...result,
                    bodySectionMeasures: [
                        ...defaultMeasures.map((measure) => ({
                            ...measure,
                            idBodySection: measure.idBodySection.toString(),
                        })),
                    ],
                })
        } else setResultBodyParts([])
    }, [result.bodyAreas])

    const startCreateResultProcedure = async () => {
        setIsLoading(true)
        try {
            const data = JSON.parse(JSON.stringify(result))
            delete data.id
            data.idFrequency = data.idFrequency === '-1' ? '1' : data.idFrequency
            data.sessionsNumber = data.sessionsNumber ? parseInt(data.sessionsNumber, 10) : 0
            data.idCenter = selectedCenterId > -1 ? selectedCenterId : null
            data.bodySectionMeasures = data.bodySectionMeasures.filter((bsm: BodySectionMeasure) => bsm.measure)
            data.protocols = protocolsToImport.map((p) => ({
                idProtocol: p?.id || '',
                sessionDuration: p?.sessionDuration?.value || '0',
            }))
            const keys = Object.keys(data) as (keyof ResultToCreate)[]
            const excludedKeys = ['idCenter']
            keys.forEach((key) => {
                if (excludedKeys.includes(key)) return
                if (data[key] === null || data[key] === -1 || data[key] === '-1') delete data[key]
            })
            const res = await createSingleResult(user.loggedUserData?.authToken || '', data)
            if (res) {
                for (let i = 0; i < resultMediaToUpload.length; i++) {
                    if (resultMediaToUpload[i].length === 0) {
                        continue
                    }
                    await startUploadProcess(
                        res?.id || '-1',
                        resultMediaToUpload[i],
                        data.startDate || '',
                        data.endDate || ''
                    )
                }
                ToastSuccess('Risultato creato con successo')
                // navigate(`${ROUTES.RESULT_DETAIL_ROUTE}${res.id}`)
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di creazione!')
        }
        setIsLoading(false)
    }

    const startUploadProcess = async (
        resultId: string,
        rawMediaArray: { file: any; idBodyPart: number }[],
        resultStartDate: string,
        resultEndDate: string
    ) => {
        try {
            for (let i = 0; i < rawMediaArray.length; i++) {
                const rawMedia = rawMediaArray[i]

                if (!rawMedia || !rawMedia.file || !(rawMedia.file instanceof File)) return

                const newResultMedia: ResultMediaToCreate = {
                    idResult: parseInt(resultId, 10),
                    idBodyPart: rawMedia.idBodyPart,
                    sessionDate: i === 0 ? resultStartDate : resultEndDate,
                    sessionIndex: i,
                }
                const createdMedia: ResultMedia | undefined = await createResultMedia(
                    user.loggedUserData?.authToken || '',
                    newResultMedia
                )
                if (!createdMedia) throw new Error('Error during creation of new result media')

                const mediaFile = await addMediaFile(
                    user.loggedUserData?.authToken || '',
                    rawMedia.file,
                    createdMedia.id?.toString() || '',
                    MediaFilesEntity.RESULT_MEDIA
                )

                mediaFile
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di caricamento dei media!')
        }
    }

    const getProtocolsData = async () => {
        try {
            const fetchedProtocols: (Protocol | undefined)[] = await Promise.all(
                protocolsToImportIds.map(
                    async (protocolId) => await getSingleProtocol(user.loggedUserData?.authToken || '', protocolId)
                )
            )
            if (fetchedProtocols) {
                const validProtocols = fetchedProtocols.filter((protocol) => protocol !== undefined)
                setProtocolsToImport(validProtocols)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (result.idApplicationType !== 1) {
            setResult({ ...result, bodyAreas: ['7'] })
        }
    }, [result.idApplicationType])

    useEffect(() => {
        if (!protocolsToImportIds) return
        void getProtocolsData()
    }, [protocolsToImportIds])

    useEffect(() => {
        if (resetSelectedCenter) setResetSelectedCenter(false)
    }, [resetSelectedCenter])

    const dataIsNotCorrect = useMemo(() => {
        const baseDataIsNotCorrect = result.idCustomerGender === '-1'
        const beautyDataIsNotCorrect =
            result.goals.length === 0 ||
            result.priorities.length === 0 ||
            result.bodyAreas.length === 0 ||
            result.idPhisicalActivity === '-1' ||
            result.idLifeMoment === '-1' ||
            result.skinTypes.length === 0 ||
            result.idFrequency === '-1'
        const wellnessDataIsNotCorrect =
            result.goals.length === 0 || result.idPhisicalActivity === '-1' || result.idLifeMoment === '-1'
        const medicalDataIsNotCorrect =
            result.medicalGroups.length === 0 ||
            result.medicalProblems.length === 0 ||
            result.idLifeMoment === '-1' ||
            result.idPhisicalActivity === '-1' ||
            result.idFrequency === '-1'
        return baseDataIsNotCorrect || result.idApplicationType === 1
            ? beautyDataIsNotCorrect
            : result.idApplicationType === 2
            ? wellnessDataIsNotCorrect
            : medicalDataIsNotCorrect
    }, [result])

    return (
        <>
            {showImportProtocolModal && (
                <ImportProtocolModal
                    modalTitle="Importa protocolli"
                    importedIds={protocolsToImportIds}
                    onClose={() => setShowImportProtocolModal(false)}
                    onConfirm={(protocolIds) => {
                        setProtocolsToImportIds([...protocolsToImportIds, ...protocolIds])
                        setShowImportProtocolModal(false)
                    }}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box" />
                <div className="page-header-section__right-box">
                    <div style={{ display: 'flex', gap: 16 }}>
                        <Button
                            buttonType="secondary"
                            onClick={() => navigate(ROUTES.RESULTS_ROUTE)}
                            loading={isLoading}
                        >
                            Annulla
                        </Button>
                        <Button
                            loading={isLoading}
                            disabled={isLoading || dataIsNotCorrect}
                            buttonType="primary"
                            onClick={() => {
                                void startCreateResultProcedure()
                            }}
                        >
                            Carica risultato
                        </Button>
                    </div>
                </div>
            </div>
            <div className="page-content-flow">
                <div className="elevated-card full-width">
                    <div className="input-form-box">
                        <div className="input-form-box__three-col-row">
                            <div style={{ marginLeft: 6 }}>
                                <div style={{ marginBottom: 6 }}>
                                    <UppercaseLabel>Data di inizio</UppercaseLabel>
                                </div>
                                <Datepicker
                                    sendDate={(date) => setResult({ ...result, startDate: date?.toISOString() || '' })}
                                />
                            </div>
                            <div style={{ marginLeft: 6 }}>
                                <div style={{ marginBottom: 6 }}>
                                    <UppercaseLabel>Data di fine</UppercaseLabel>
                                </div>
                                <Datepicker
                                    sendDate={(date) => setResult({ ...result, endDate: date?.toISOString() || '' })}
                                />
                            </div>
                            <SingleSelect
                                inputLabel="tipologia"
                                placeholder="Seleziona la tipologia"
                                options={data.appTypes.map((appType) => ({
                                    value: appType.id.toString(),
                                    label: appType.name,
                                }))}
                                onValueChange={(newVal) => {
                                    if (!newVal) return
                                    setResult({
                                        ...result,
                                        idApplicationType: parseInt(newVal.value, 10),
                                    })
                                }}
                            />
                            <AsyncSingleSelect
                                inputLabel="centro"
                                placeholder="Seleziona il centro di riferimento"
                                options={[]}
                                endpoint="centers"
                                reset={resetSelectedCenter}
                                userToken={user.loggedUserData?.authToken}
                                onValueChange={(newVal) => {
                                    if (!newVal) return
                                    setSelectedCenterId(parseInt(newVal.value, 10))
                                }}
                            />
                            {selectedCenterId > -1 && (
                                <Button
                                    style={{ marginTop: 21 }}
                                    buttonType="primary"
                                    onClick={() => {
                                        setSelectedCenterId(-1)
                                        setResetSelectedCenter(!resetSelectedCenter)
                                    }}
                                >
                                    Cancella selezione
                                </Button>
                            )}
                        </div>
                        <div className="horizontal-line-separator" />
                        <div className="input-form-box__three-col-row">
                            <SingleSelect
                                inputLabel="genere"
                                placeholder="Seleziona il genere"
                                options={data.customerGenders.map((customerGender) => ({
                                    value: customerGender.id.toString(),
                                    label: customerGender.name,
                                }))}
                                onValueChange={(newVal) => {
                                    if (!newVal) return
                                    setResult({
                                        ...result,
                                        idCustomerGender: newVal.value,
                                    })
                                }}
                            />
                            <TextInput
                                inputLabel="età cliente"
                                value={result?.customerAge || ''}
                                onValueChange={(newVal) => setResult({ ...result, customerAge: newVal })}
                            />

                            <SingleSelect
                                inputLabel="momento della vita"
                                placeholder="Seleziona un momento della vita"
                                options={data.lifeMoments
                                    .filter((lifeM) =>
                                        lifeM.applicationTypes
                                            .map((appType) => appType.id)
                                            .includes(result.idApplicationType)
                                    )
                                    .filter((lM) => {
                                        if (result.idCustomerGender === '1') {
                                            return lM.id !== 1
                                        }
                                        return lM
                                    })
                                    .map((lifeMoment) => ({
                                        value: lifeMoment.id.toString(),
                                        label: lifeMoment.name,
                                    }))}
                                onValueChange={(newVal) => setResult({ ...result, idLifeMoment: newVal?.value || '' })}
                            />
                            {result.idCustomerGender ===
                                data.customerGenders.find((gender) => gender.name === 'Femmina')?.id.toString() && (
                                <>
                                    <SingleSelect
                                        inputLabel="gravidanze"
                                        placeholder="Seleziona un valore"
                                        options={pregnancyOptions}
                                        onValueChange={(newVal) =>
                                            setResult({ ...result, customerPregnancy: newVal?.value === '2' || null })
                                        }
                                    />
                                    {result.customerPregnancy && result.customerPregnancy && (
                                        <TextInput
                                            inputLabel="numero di gravidanze"
                                            value={result.customerPregnancyNumber || ''}
                                            onValueChange={(newVal) =>
                                                setResult({ ...result, customerPregnancyNumber: newVal })
                                            }
                                        />
                                    )}
                                    <SingleSelect
                                        inputLabel="parto"
                                        placeholder="Seleziona un tipo di parto"
                                        options={data.childbirthTypes.map((childbirthType) => ({
                                            value: childbirthType.id.toString(),
                                            label: childbirthType.name,
                                        }))}
                                        onValueChange={(newVal) =>
                                            setResult({ ...result, idChildbirthType: newVal?.value || '' })
                                        }
                                    />
                                </>
                            )}
                            <SingleSelect
                                inputLabel="Livello attività fisica"
                                placeholder="Seleziona un livello"
                                options={data.phisicalActivities.map((phisicalActivity) => ({
                                    value: phisicalActivity.id.toString(),
                                    label: phisicalActivity.name,
                                }))}
                                onValueChange={(newVal) =>
                                    setResult({ ...result, idPhisicalActivity: newVal?.value || '' })
                                }
                            />
                            <SingleSelect
                                inputLabel="idratazione"
                                placeholder="Seleziona un livello"
                                options={hydrationOptions}
                                onValueChange={(newVal) =>
                                    setResult({ ...result, customerHydration: newVal?.value || '' })
                                }
                            />
                            <SingleSelect
                                inputLabel="Dieta sana"
                                placeholder="Seleziona un parametro"
                                options={data.healthyDiets.map((healthyDiet) => ({
                                    value: healthyDiet.id.toString(),
                                    label: healthyDiet.name,
                                }))}
                                onValueChange={(newVal) => setResult({ ...result, idHealtyDiet: newVal?.value || '' })}
                            />
                            <SingleSelect
                                inputLabel="Fumo"
                                placeholder="Seleziona un valore"
                                options={smokeOptions}
                                onValueChange={(newVal) =>
                                    setResult({ ...result, customerSmoke: newVal?.value === 'yes' || null })
                                }
                            />
                            <SingleSelect
                                inputLabel="Livello di stress"
                                placeholder="Seleziona un livello"
                                options={data.stressLevels.map((stressLevel) => ({
                                    value: stressLevel.id.toString(),
                                    label: stressLevel.name,
                                }))}
                                onValueChange={(newVal) => setResult({ ...result, idStressLevel: newVal?.value || '' })}
                            />
                            <SingleSelect
                                inputLabel="Consumo di alcolici"
                                placeholder="Seleziona un parametro"
                                options={data.alcoholConsumptions.map((alcoholConsumption) => ({
                                    value: alcoholConsumption.id.toString(),
                                    label: alcoholConsumption.name,
                                }))}
                                onValueChange={(newVal) =>
                                    setResult({ ...result, idAlcoholConsumption: newVal?.value || '' })
                                }
                            />
                            {[1, 2].includes(result.idApplicationType) && (
                                <TextareaInput
                                    inputLabel="Professione"
                                    placeholder="Descrivi la professione del cliente"
                                    value={result.customerProfession || ''}
                                    onValueChange={(newVal) => setResult({ ...result, customerProfession: newVal })}
                                />
                            )}
                            {result.idApplicationType === 1 && (
                                <>
                                    <SingleSelect
                                        inputLabel="Protezione durante l'esposizione al sole"
                                        placeholder="Seleziona un valore"
                                        options={smokeOptions}
                                        onValueChange={(newVal) =>
                                            setResult({
                                                ...result,
                                                customerSunProtection: newVal?.value === 'yes' || null,
                                            })
                                        }
                                    />
                                    <TextareaInput
                                        inputLabel="Dettagli skin care quotidiana"
                                        placeholder="Descrivi la skin care del cliente"
                                        value={result.customerSkinCare || ''}
                                        onValueChange={(newVal) => setResult({ ...result, customerSkinCare: newVal })}
                                    />
                                </>
                            )}
                            {result.idApplicationType === 2 && (
                                <TextareaInput
                                    inputLabel="Trattamenti estetici in corso"
                                    placeholder="Descrivi i trattamenti estetici del cliente"
                                    value={result.customerCurrentTreatments}
                                    onValueChange={(newVal) =>
                                        setResult({ ...result, customerCurrentTreatments: newVal })
                                    }
                                />
                            )}
                        </div>
                        {/* {result.bodyAreas.includes('7') && (
                            <>
                                <div className="horizontal-line-separator" />
                                <div className="input-form-box__three-col-row">
                                    <div>
                                        <div style={{ marginBottom: 6 }}>
                                            <UppercaseLabel>Misure corpo</UppercaseLabel>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-form-box__three-col-row">
                                    {data.bodySections.map((bodySection) => (
                                        <FixedMeasureInput
                                            key={bodySection.id}
                                            inputLabel={bodySection.name}
                                            value={
                                                result.bodySectionMeasures.find(
                                                    (section) => section.idBodySection === bodySection.id.toString()
                                                )?.measure || ''
                                            }
                                            type="number"
                                            min={0}
                                            onValueChange={(newVal) =>
                                                setResult({
                                                    ...result,
                                                    bodySectionMeasures: result.bodySectionMeasures.map(
                                                        (bodySectionMeasure) => {
                                                            if (
                                                                bodySectionMeasure.idBodySection ===
                                                                bodySection.id.toString()
                                                            )
                                                                return {
                                                                    ...bodySectionMeasure,
                                                                    measure: newVal,
                                                                }
                                                            return bodySectionMeasure
                                                        }
                                                    ),
                                                })
                                            }
                                            valuePostfix="cm"
                                        />
                                    ))}
                                    <FixedMeasureInput
                                        inputLabel="peso"
                                        value={result.customerWeight || ''}
                                        min={0}
                                        type="number"
                                        onValueChange={(newVal) => setResult({ ...result, customerWeight: newVal })}
                                        valuePostfix="Kg"
                                    />
                                </div>
                            </>
                        )} */}
                        <div className="horizontal-line-separator" />
                        <div className="input-form-box__three-col-row">
                            {[1, 3].includes(result.idApplicationType) && (
                                <>
                                    <MultiSelect
                                        inputLabel="tipo di pelle"
                                        placeholder="Seleziona uno o più tipi di pelle"
                                        values={formatItemsForSelectOptions<InfoItem>(
                                            data.skinTypes.filter(
                                                (skinType) => result.skinTypes.indexOf(skinType.id.toString()) > -1
                                            )
                                        )}
                                        options={data.skinTypes.map((skinType) => ({
                                            value: skinType.id.toString(),
                                            label: skinType.name,
                                        }))}
                                        onValueChange={(newValues) =>
                                            setResult({ ...result, skinTypes: newValues.map((value) => value.value) })
                                        }
                                    />
                                    {result.idApplicationType === 1 && (
                                        <MultiSelect
                                            inputLabel="area interessata"
                                            placeholder="Seleziona una o più aree del corpo"
                                            values={formatItemsForSelectOptions<InfoItem>(
                                                data.bodyAreas.filter(
                                                    (bodyArea) => result.bodyAreas.indexOf(bodyArea.id.toString()) > -1
                                                )
                                            )}
                                            options={data.bodyAreas.map((bodyArea) => ({
                                                value: bodyArea.id.toString(),
                                                label: bodyArea.name,
                                            }))}
                                            onValueChange={(newValues) =>
                                                setResult({
                                                    ...result,
                                                    bodyAreas: newValues.map((value) => value.value),
                                                })
                                            }
                                        />
                                    )}
                                </>
                            )}
                            {[1, 2].includes(result.idApplicationType) && (
                                <MultiSelect
                                    inputLabel="obiettivi"
                                    placeholder="Seleziona uno o più obiettivi"
                                    values={formatItemsForSelectOptions<InfoItem>(
                                        data.goals.filter((goal) => result.goals.indexOf(goal.id.toString()) > -1)
                                    )}
                                    disabled={[1].includes(result.idApplicationType) && result.bodyAreas.length === 0}
                                    options={data.goals
                                        .filter((g) =>
                                            g.applicationTypes
                                                .map((appType) => appType.id)
                                                .includes(result.idApplicationType)
                                        )
                                        .filter((goal) => {
                                            if (result.idApplicationType === 1) {
                                                return result.bodyAreas.find(
                                                    (bodyArea) =>
                                                        goal.bodyAreas
                                                            .map((gBodyArea) => gBodyArea.id.toString())
                                                            .indexOf(bodyArea) > -1
                                                )
                                            }
                                            return true
                                        })
                                        .map((goal) => ({
                                            value: goal.id.toString(),
                                            label: goal.name,
                                        }))}
                                    onValueChange={(newValues) => {
                                        setResult({ ...result, goals: newValues.map((value) => value.value) })
                                        setSelectedGoalsIds(newValues.map((value) => value.value))
                                    }}
                                />
                            )}
                            {result.idApplicationType === 1 && (
                                <>
                                    <MultiSelect
                                        inputLabel="priorità"
                                        placeholder="Seleziona una o più priorità"
                                        disabled={result.goals.length === 0}
                                        values={formatItemsForSelectOptions<InfoItem>(
                                            data.priorities.filter(
                                                (priority) => result.priorities.indexOf(priority.id.toString()) > -1
                                            )
                                        )}
                                        options={data.priorities
                                            .filter((priority) =>
                                                result.bodyAreas.find(
                                                    (bodyArea) =>
                                                        priority.bodyAreas
                                                            .map((pBodyArea) => pBodyArea.id.toString())
                                                            .indexOf(bodyArea) > -1
                                                )
                                            )
                                            .filter(
                                                (problem) => selectedGoalsIds.indexOf(problem.goal.id.toString()) > -1
                                            )
                                            .map((priority) => ({
                                                value: priority.id.toString(),
                                                label: priority.name,
                                            }))}
                                        onValueChange={(newValues) =>
                                            setResult({ ...result, priorities: newValues.map((value) => value.value) })
                                        }
                                    />
                                    <SingleSelect
                                        inputLabel="intensità del problema"
                                        placeholder="Seleziona l'intensità del problema"
                                        value={formatItemForSelectOptions<InfoItem>(
                                            data.priorityIntensities.find(
                                                (problemIntensity) =>
                                                    result.idPriorityIntensity === problemIntensity.id.toString()
                                            )
                                        )}
                                        options={data.priorityIntensities.map((priorityIntensity) => ({
                                            value: priorityIntensity.id.toString(),
                                            label: priorityIntensity.name,
                                        }))}
                                        onValueChange={(newValue) => {
                                            if (!newValue) return
                                            setResult({ ...result, idPriorityIntensity: newValue.value })
                                        }}
                                    />
                                </>
                            )}
                            {result.idApplicationType === 3 && (
                                <>
                                    <MultiSelect
                                        inputLabel="Problematica medicale"
                                        placeholder="Seleziona uno o più gruppi medicali"
                                        values={formatItemsForSelectOptions<InfoItem>(
                                            data.medicalGroups.filter(
                                                (mGroup) =>
                                                    result.medicalGroups.toString().indexOf(mGroup.id.toString()) > -1
                                            )
                                        )}
                                        options={data.medicalGroups.map((mGroups) => {
                                            return {
                                                value: mGroups.id.toString(),
                                                label: mGroups.name,
                                            }
                                        })}
                                        onValueChange={(newValues) => {
                                            setResult({
                                                ...result,
                                                medicalGroups: newValues.map((value) => parseInt(value.value, 10)),
                                            })
                                            setSelectedMedicalGroups(newValues.map((value) => value.value))
                                        }}
                                    />
                                    <MultiSelect
                                        inputLabel="Dettaglio problematica"
                                        placeholder="Seleziona uno o più problemi medicali"
                                        values={formatItemsForSelectOptions<InfoItem>(
                                            data.medicalProblems.filter(
                                                (mProblem) =>
                                                    result.medicalProblems.includes(mProblem.id)
                                            )
                                        )}
                                        disabled={result.medicalGroups.length === 0}
                                        options={data.medicalProblems
                                            .filter((mP) =>
                                                selectedMedicalGroups.toString().includes(mP.medicalGroup.id.toString())
                                            )
                                            .map((mProblem) => {
                                                return {
                                                    value: mProblem.id.toString(),
                                                    label: mProblem.name,
                                                }
                                            })}
                                        onValueChange={(newValues) => {
                                            setResult({
                                                ...result,
                                                medicalProblems: newValues.map((value) => parseInt(value.value, 10)),
                                            })
                                        }}
                                    />
                                </>
                            )}
                            <TextareaInput
                                inputLabel="Descrizione problema"
                                placeholder="Descrivi brevemente il problema del cliente"
                                value={result.priorityDescription}
                                onValueChange={(newVal) => setResult({ ...result, priorityDescription: newVal })}
                            />
                            <TextareaInput
                                inputLabel="Descrizione risultato"
                                placeholder="Descrivi brevemente il risultato raggiunto"
                                value={result.resultDescription}
                                onValueChange={(newVal) => setResult({ ...result, resultDescription: newVal })}
                            />
                            {result.idApplicationType === 2 ? (
                                <TextInput
                                    inputLabel="numero di sessioni"
                                    value={result.sessionsNumber || ''}
                                    onValueChange={(newVal) => setResult({ ...result, sessionsNumber: newVal })}
                                />
                            ) : (
                                <SingleSelect
                                    inputLabel="numero di sessioni"
                                    placeholder="Seleziona il numero di sessioni"
                                    value={formatItemForSelectOptions<SimpleEntity>(
                                        data.sessionsNumbers.find(
                                            (sessionsNumber) => result.sessionsNumber === sessionsNumber.id
                                        )
                                    )}
                                    options={data.sessionsNumbers.map((sessionsNumber) => ({
                                        value: sessionsNumber.id,
                                        label: sessionsNumber.value,
                                    }))}
                                    onValueChange={(newValue) => {
                                        if (!newValue) return
                                        setResult({ ...result, sessionsNumber: newValue.value })
                                    }}
                                />
                            )}

                            <SingleSelect
                                inputLabel="frequenza"
                                placeholder="Seleziona la frequenza"
                                value={formatItemForSelectOptions<SimpleEntity>(
                                    data.frequencies.find((frequency) => result.idFrequency === frequency.id)
                                )}
                                options={data.frequencies.map((frequency) => ({
                                    value: frequency.id,
                                    label: frequency.name ?? '',
                                }))}
                                onValueChange={(newValue) => {
                                    if (!newValue) return
                                    setResult({ ...result, idFrequency: newValue.value })
                                }}
                            />
                            <MultiSelect
                                inputLabel="Dispositivi"
                                placeholder="Seleziona uno o più dispositivi"
                                options={data.devices.map((device) => {
                                    return { value: device.id.toString(), label: device.name }
                                })}
                                onValueChange={(newValues) => {
                                    setResult({
                                        ...result,
                                        devices: newValues.map((newVal) => newVal.value),
                                    })
                                }}
                            />
                        </div>
                        <div className="horizontal-line-separator" />
                        <Button buttonType="primary" onClick={() => setShowImportProtocolModal(true)}>
                            Importa protocolli
                        </Button>
                        <div className="input-form-box__three-col-row">
                            {protocolsToImport &&
                                protocolsToImport.map((protocolToImport) => {
                                    if (!protocolToImport) return
                                    return (
                                        <div key={protocolToImport.id} style={{ width: '100%' }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    gap: 16,
                                                    margin: '12px 0',
                                                }}
                                            >
                                                <div style={{ marginLeft: 6 }}>
                                                    <UppercaseLabel>Protocollo</UppercaseLabel>
                                                    <div>{protocolToImport.name}</div>
                                                </div>
                                                <Button
                                                    buttonType="ghost"
                                                    onClick={() => {
                                                        setProtocolsToImport(
                                                            protocolsToImport.filter(
                                                                (protocol) => protocol?.id !== protocolToImport.id
                                                            )
                                                        )
                                                        setProtocolsToImportIds(
                                                            protocolsToImportIds.filter(
                                                                (protocolId) =>
                                                                    protocolId !== protocolToImport.id.toString()
                                                            )
                                                        )
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} size="xs" color="red" />
                                                </Button>
                                            </div>
                                            <FixedMeasureInput
                                                inputLabel="Durata sessioni"
                                                placeholder=""
                                                value={protocolToImport.sessionDuration?.value ?? '0'}
                                                min={0}
                                                type="number"
                                                onValueChange={(newVal) =>
                                                    setProtocolsToImport(
                                                        protocolsToImport.map((p) => {
                                                            if (p?.id === protocolToImport.id)
                                                                return {
                                                                    ...protocolToImport,
                                                                    sessionDuration: {
                                                                        id: '-1',
                                                                        value: newVal,
                                                                    },
                                                                }
                                                            return protocolToImport
                                                        })
                                                    )
                                                }
                                                valuePostfix="minuti"
                                            />
                                        </div>
                                    )
                                })}
                        </div>
                        {resultBodyParts && resultBodyParts.length > 0 && (
                            <>
                                <div className="horizontal-line-separator" />
                                <div className="input-form-box__three-col-row">
                                    <div style={{ marginLeft: 6 }}>
                                        <div style={{ marginBottom: 6 }}>
                                            <UppercaseLabel>avanzamento temporale</UppercaseLabel>
                                        </div>
                                    </div>
                                    <div />
                                    <div />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'flex-start', gap: 16, overflowX: 'auto' }}>
                                    {resultBodyParts.map((resultBodyPart, i) => {
                                        return (
                                            <ResultMediaSection
                                                index={i}
                                                key={resultBodyPart.id}
                                                selectedBodyPartCoverId={result.idBodyPartCover}
                                                canEdit
                                                canDelete
                                                idResult="-1"
                                                bodyPart={resultBodyPart}
                                                onRawMediaChange={(rawMediaArray) => {
                                                    const currentResultMediaToUpload = cloneDeep(resultMediaToUpload)
                                                    currentResultMediaToUpload[i] = rawMediaArray
                                                    setResultMediaToUpload(currentResultMediaToUpload)
                                                }}
                                                onSelectedBodyPartCoverIdChange={(selectedBodyPartCoverId) =>
                                                    setResult({
                                                        ...result,
                                                        idBodyPartCover: selectedBodyPartCoverId,
                                                    })
                                                }
                                            />
                                        )
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewResultPage
