import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../resources/routes-constants'
import TabsSelector, { Tab } from '../../components/TabsSelector/TabsSelector'
import Messages from './Tabs/Messages'
import CenterResults from './Tabs/CenterResults'
import CenterAnagraphic from './Tabs/CenterAnagraphic'
import { getElementIdFromCurrentRoute } from '../../utility/functions'
import StatusLabel from '../../components/Badges/StatusLabel'
import { EntityStatus, NotificationEntityType, NotificationType } from '../../types/data'
import { useAppSelector } from '../../store/reducers/store'
import CenterUsers from './Tabs/CenterUsers'

const defaultPageTabs: Tab[] = [
    {
        value: 'anagraphics',
        label: 'Anagrafica',
    },
    {
        value: 'users',
        label: 'Utenti',
    },
    {
        value: 'results',
        label: 'Risultati',
    },
    {
        value: 'messages',
        label: 'Messaggi',
    },
]

const CenterDetailPage: React.FC = () => {
    const notification = useAppSelector((appData) => appData.notification.items)
    const [pageTabs, setPageTabs] = useState(defaultPageTabs)
    const [selectedTab, setSelectedTab] = useState<{ value: string; label: string }>(pageTabs[0])
    const [centerName, setCenterName] = useState('')
    const [centerStatus, setCenterStatus] = useState(EntityStatus.PENDING)
    const [centerId, setCenterId] = useState(getElementIdFromCurrentRoute(window.location.pathname))

    useEffect(() => {
        const currentCenterMessageNotifications = notification.filter(
            (notif) =>
                notif.idEntity === centerId &&
                notif.entity === NotificationEntityType.center &&
                notif.type === NotificationType.message
        )

        if (currentCenterMessageNotifications.length > 0) {
            pageTabs[2].notification = {
                value: currentCenterMessageNotifications.length,
                type: NotificationEntityType.message,
            }
            setPageTabs(pageTabs)
        } else {
            pageTabs[2].notification = undefined
            setPageTabs(pageTabs)
        }

        const currentCenterResultsNotifications = notification.filter(
            (notif) =>
                notif.additionalData !== undefined &&
                notif.additionalData?.fatherId === parseInt(centerId, 10) &&
                notif.additionalData?.father === NotificationEntityType.center &&
                notif.type === NotificationType.result
        )

        if (currentCenterResultsNotifications.length > 0) {
            pageTabs[1].notification = {
                value: currentCenterResultsNotifications.length,
                type: NotificationEntityType.results,
            }
            setPageTabs(pageTabs)
        } else {
            pageTabs[1].notification = undefined
            setPageTabs(pageTabs)
        }
    }, [notification, centerId])

    const renderCurrentTab = useMemo(() => {
        switch (selectedTab.value) {
            case 'results':
                return <CenterResults centerId={centerId} />
            case 'users':
                return <CenterUsers centerId={centerId} />
            case 'messages':
                return <Messages centerId={centerId} />
            case 'anagraphics':
            default:
                return (
                    <CenterAnagraphic
                        idCenter={centerId}
                        emitCenterData={(id, name, status) => {
                            setCenterId(id)
                            setCenterName(name)
                            setCenterStatus(status)
                        }}
                    />
                )
        }
    }, [selectedTab, centerId])

    return (
        <>
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">
                        <Link to={ROUTES.CENTERS_ROUTE}>Elenco centri</Link> /{' '}
                        {centerId === '-1' ? 'Nuovo centro' : centerName || ''}
                        <div style={{ display: 'inline-block', marginLeft: 8, fontSize: 14 }}>
                            <StatusLabel status={centerStatus} />
                        </div>
                    </span>
                </div>
            </div>
            <TabsSelector
                tabs={pageTabs}
                activeTabValue={selectedTab.value}
                onChangeTab={(newTab) => setSelectedTab(newTab)}
            />
            {renderCurrentTab}
        </>
    )
}

export default CenterDetailPage
