import React, { useEffect, useState } from 'react'
import Button from '../../components/Buttons/Button'
import TableHeader from '../../components/Table/TableHeader'
import Table, { TableAction, TableColumn } from '../../components/Table/Table'
import TextInput from '../../components/Inputs/TextInput'
import AdvancedSearch from '../../components/AdvancedSearch/AdvancedSearch'
import MultiSelect from '../../components/Inputs/MultiSelect'
import { ROUTES } from '../../resources/routes-constants'
import { useNavigate } from 'react-router-dom'
import { AppType, Device, InfoItem, Priority, Protocol } from '../../types/data'
import { useAppSelector } from '../../store/reducers/store'
import TextLabel from '../../components/Badges/TextLabel'
import Link from '../../components/Link/Link'
import { confirmFilterObject, formatItemsForSelectOptions } from '../../utility/functions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import AlertModal from '../../components/Modal/AlertModal'
import { deleteSingleProtocol } from '../../resources/api-constants'
import { ToastError } from '../../utility/toast'

interface ProtocolsSearchFilters {
    name: string
    application_types: string[]
    devices: string[]
    show_unconfigured: boolean
}

const defaultFiltersState: ProtocolsSearchFilters = {
    name: '',
    application_types: [],
    devices: [],
    show_unconfigured: true,
}

const ProtocolsPage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const data = useAppSelector((data) => data.data)
    const navigate = useNavigate()
    const [selectedProtocol, setSelectedProtocol] = useState<Protocol | null>(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [forceDeleteReload, setForceDeleteReload] = useState(false)

    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)
    const [resetFlag, setResetFlag] = useState(false)
    const [protocolsFilters, setProtocolsFilters] = useState<ProtocolsSearchFilters>(defaultFiltersState)
    const [finalFilters, setFinalFilters] = useState<ProtocolsSearchFilters | undefined>(defaultFiltersState)

    useEffect(() => {
        if (resetFlag) setResetFlag(!resetFlag)
    }, [resetFlag])

    useEffect(() => {
        if (forceDeleteReload) setForceDeleteReload(!forceDeleteReload)
    }, [forceDeleteReload])

    const resetSearchParams = () => {
        if (JSON.stringify(protocolsFilters) === JSON.stringify(defaultFiltersState)) return
        setResetFlag(true)
        setProtocolsFilters(defaultFiltersState)
        setFinalFilters(confirmFilterObject(defaultFiltersState))
    }

    const startDeleteProcedure = (protocol: Protocol) => {
        setSelectedProtocol(protocol)
        setShowDeleteModal(true)
    }

    const endRemoveProtocolProcedure = () => {
        setSelectedProtocol(null)
        setShowDeleteModal(false)
        setForceDeleteReload(true)
    }

    const removeProtocol = async () => {
        try {
            const res = await deleteSingleProtocol(user.loggedUserData?.authToken || '', selectedProtocol?.id || '')
            if (res) endRemoveProtocolProcedure()
            else ToastError('Si è verificato un errore durante la procedura di eliminazione!')
        } catch (error) {
            console.error(error)
        }
    }

    const columns: TableColumn[] = [
        {
            title: 'Nome',
            field: 'name',
            render: (data: Protocol) => (
                <Link internalRoute={`${ROUTES.PROTOCOL_DETAIL_ROUTE}${data.id}`}>{data.name}</Link>
            ),
        },
        {
            title: 'Area',
            field: 'body_areas',
            sorting: false,
            render: (data) => data.bodyAreas.map((area: InfoItem) => area.name).join(','),
        },
        {
            title: 'Obiettivi',
            field: 'goals',
            sorting: false,
            render: (data) => data.goals.map((goal: InfoItem) => goal.name).join(','),
        },
        {
            title: 'Problemi da risolvere',
            field: 'priorities',
            sorting: false,
            render: (data) => data.priorities.map((priority: Priority) => priority.name).join(','),
        },
        {
            title: 'Durata per sessione (m)',
            field: 'duration',
            render: (data: Protocol) => <p>{data.sessionDuration?.value}</p>,
        },
        {
            title: 'Tipologia',
            field: 'application_types',
            sorting: false,
            render: (data: Protocol) => (
                <TextLabel key={data.applicationType.id} customColor={data.applicationType.color}>
                    {data.applicationType.name}
                </TextLabel>
            ),
        },
    ]

    const actions: TableAction[] = [
        (rowData: Protocol) => ({
            icon: () => <FontAwesomeIcon icon={faPen} size="xs" />,
            onClick: () => navigate(`${ROUTES.PROTOCOL_DETAIL_ROUTE}${rowData.id}`),
            tooltip: 'Modifica',
        }),
        (rowData: Protocol) => ({
            icon: () => <FontAwesomeIcon icon={faTrash} size="xs" />,
            onClick: () => startDeleteProcedure(rowData),
            tooltip: 'Elimina',
        }),
    ]

    return (
        <>
            {showDeleteModal && (
                <AlertModal
                    modalTitle="Elimina protocollo"
                    modalMessage={`Sei sicuro di voler eliminare l'elemento ${selectedProtocol?.name}? Questa operazione è irreversibile.`}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => void removeProtocol()}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">Elenco protocolli</span>
                </div>
                <div className="page-header-section__right-box">
                    <Button
                        customClassName={`${showAdvancedSearch ? 'active' : ''}`}
                        buttonType="ghost"
                        onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                    >
                        Ricerca avanzata
                    </Button>
                    <Button buttonType="primary" onClick={() => navigate(`${ROUTES.PROTOCOL_DETAIL_ROUTE}new`)}>
                        Aggiungi protocollo
                    </Button>
                </div>
            </div>
            <div className="page-content-block">
                <AdvancedSearch
                    isVisible={showAdvancedSearch}
                    onReset={() => resetSearchParams()}
                    onSearch={() => setFinalFilters(confirmFilterObject(protocolsFilters))}
                >
                    <div className="input-form-box__three-col-row">
                        <TextInput
                            inputLabel="nome"
                            value={protocolsFilters.name}
                            onValueChange={(newVal) => setProtocolsFilters({ ...protocolsFilters, name: newVal })}
                        />
                        <MultiSelect
                            reset={resetFlag}
                            inputLabel="tipologia"
                            placeholder="Seleziona una o più tipologie"
                            options={data.appTypes.map((appType) => {
                                return { value: appType.id.toString(), label: appType.name }
                            })}
                            values={formatItemsForSelectOptions<AppType>(
                                data.appTypes.filter(
                                    (appType) => protocolsFilters.application_types.indexOf(appType.name) !== -1
                                )
                            )}
                            onValueChange={(newVals) =>
                                setProtocolsFilters({
                                    ...protocolsFilters,
                                    application_types: newVals.map((val) => val.value),
                                })
                            }
                        />
                        <MultiSelect
                            reset={resetFlag}
                            inputLabel="dispositivi associati"
                            placeholder="Seleziona uno o più dispositivi"
                            options={data.devices.map((device) => {
                                return { value: device.id.toString(), label: device.name }
                            })}
                            values={formatItemsForSelectOptions<Device>(
                                data.devices.filter(
                                    (device) => protocolsFilters.devices.indexOf(device.id.toString()) !== -1
                                )
                            )}
                            onValueChange={(selectedOption) =>
                                setProtocolsFilters({
                                    ...protocolsFilters,
                                    devices: selectedOption.map((opt) => opt.value) || [],
                                })
                            }
                        />
                    </div>
                </AdvancedSearch>

                <div className="relative">
                    <TableHeader positionIndex={23} />
                    <Table
                        actions={actions}
                        reloads={resetFlag}
                        forceDeleteReload={forceDeleteReload}
                        columns={columns}
                        filterParams={finalFilters}
                        endpoint="protocols"
                    />
                </div>
            </div>
        </>
    )
}

export default ProtocolsPage
