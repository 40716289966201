import { NotificationsTarget } from '../types/data'

export const mailRegex =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
export const floatNumberRegex = /^([0-9]*[.,])?[0-9]+$/

export const icooneMediaPlaceholderImage =
    'https://icoone-media.s3.eu-west-1.amazonaws.com/defaults/badge_avatar_placeholder.png'
export enum IcooneMaterialScope {
    afterSales = 3,
    marketing = 2,
    training = 1,
}

export const TargetEntity = [
    {
        value: 'CENTER',
        label: 'Centro',
    },
    {
        value: 'DEALER',
        label: 'Distributore',
    },
    {
        value: 'ALL',
        label: 'Tutti',
    },
]

export const NotificationTargetEntity: {
    value: string
    label: string
}[] = [
    {
        value: NotificationsTarget.centers,
        label: 'Centro',
    },
    {
        value: NotificationsTarget.dealers,
        label: 'Distributore',
    },
    {
        value: NotificationsTarget.all,
        label: 'Tutti',
    },
]

export const IcooneMediaShareType = [
    {
        value: 'VIEW',
        label: 'Visualizzazione',
    },
    {
        value: 'DOWNLOAD',
        label: 'Scaricabile',
    },
    {
        value: 'DOWNLOAD_REQUEST',
        label: 'Richiesta di invio ',
    },
]

export const MediaFormats = [
    {
        value: '1',
        label: 'PDF',
    },
    {
        value: '2',
        label: 'Immagine',
    },
    {
        value: '3',
        label: 'Audio',
    },
    {
        value: '4',
        label: 'Video',
    },
]

export const sessionsNumber = [
    {
        value: '1',
        label: '5',
    },
    {
        value: '2',
        label: '10',
    },
    {
        value: '3',
        label: '15',
    },
    {
        value: '4',
        label: '20',
    },
]

export const sessionDurations = [
    {
        value: '1',
        label: '20',
    },
    {
        value: '2',
        label: '25',
    },
    {
        value: '3',
        label: '30',
    },
    {
        value: '4',
        label: '40',
    },
    {
        value: '5',
        label: '45',
    },
    {
        value: '6',
        label: '50',
    },
    {
        value: '7',
        label: '60',
    },
    {
        value: '8',
        label: '90',
    },
]
