import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './modals.sass'
import Button from '../Buttons/Button'
import TextInput from '../Inputs/TextInput'
import SingleSelect from '../Inputs/SingleSelect'
import { useAppSelector } from '../../store/reducers/store'
import { createEntitySubUser, updateUser } from '../../resources/api-constants'
import { ToastError, ToastSuccess } from '../../utility/toast'
import { UserToSend } from '../../types/data'

const defaultEntityUser: UserToSend = {
    id: -1,
    firstName: '',
    lastName: '',
    language: 'en',
    email: '',
    idRole: -1,
}

const CreateUserModal: React.FC<{
    user: UserToSend | null
    entityId: number
    isCenterUser?: boolean
    onConfirm: () => void
    onClose: () => void
}> = ({ user, entityId, isCenterUser, onConfirm, onClose }) => {
    const loggedUser = useAppSelector((data) => data.user)
    const data = useAppSelector((data) => data.data)
    const [currentUser, setCurrentUser] = useState<UserToSend>(defaultEntityUser)
    const [isLoading, setIsLoading] = useState(false)

    const dataIsNotValid = useMemo(
        () => !currentUser.firstName || !currentUser.lastName || !currentUser.email || currentUser.idRole < 0,
        [currentUser]
    )

    const createUser = async () => {
        setIsLoading(true)
        try {
            const newUser = await createEntitySubUser(
                loggedUser.loggedUserData?.authToken || '',
                isCenterUser ? 'center' : 'dealer',
                entityId || -1,
                currentUser
            )
            if (newUser) {
                onConfirm()
                ToastSuccess('Utente creato con successo')
            }
        } catch (error) {
            console.error(error)
            ToastError("Errore durante la creazione dell'utente")
        }
        setIsLoading(false)
    }

    const updateSubUser = async () => {
        setIsLoading(true)
        try {
            const updatedSubUser = await updateUser(
                loggedUser.loggedUserData?.authToken || '',
                {
                    id: currentUser.id,
                    idRole: currentUser.idRole,
                }
            )
            if (updatedSubUser) {
                ToastSuccess('Utente aggiornato con successo')
                onConfirm()
            }
        } catch (error) {
            console.error(error)
            ToastError("Errore durante l'aggiornamento dell'utente")
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (user) {
            setCurrentUser(user)
        }
    }, [user])

    const getUserRoleObject = useCallback((roleId: number) => {
        const foundRoleObj = data.userRoles.find((opt) => opt.id === roleId)
        return { label: foundRoleObj?.name ?? '', value: roleId.toString() }
    }, [])

    const getAvailableRoles = useCallback(() => {
        const selectedRoles = data.userRoles.filter((role) =>
            isCenterUser ? role.context === 'CENTER' : role.context === 'DEALER'
        )
        return selectedRoles
            .filter((role) => ![2, 3].includes(role.id))
            .map((role) => ({ label: role.name, value: role.id.toString() }))
    }, [data.userRoles, isCenterUser])

    return (
        <div className="modal-wrapper">
            <div className="dark-cover" />

            <div className="modal-component">
                <div className="modal-component__header">
                    <span className="page-title">{user ? 'Aggiorna utente' : 'Crea utente'}</span>
                </div>
                <div className="modal-component__body" style={{ paddingBottom: 100 }}>
                    {user ? (
                        <>
                            <p>Nome: {currentUser.firstName}</p>
                            <p>Cognome: {currentUser.lastName}</p>
                            <p>Email: {currentUser.email}</p>
                        </>
                    ) : (
                        <>
                            <TextInput
                                inputLabel="Nome"
                                value={currentUser.firstName}
                                onValueChange={(newVal) => setCurrentUser({ ...currentUser, firstName: newVal })}
                            />
                            <TextInput
                                inputLabel="Cognome"
                                value={currentUser.lastName}
                                onValueChange={(newVal) => setCurrentUser({ ...currentUser, lastName: newVal })}
                            />
                            <TextInput
                                inputLabel="Email"
                                value={currentUser.email}
                                onValueChange={(newVal) => setCurrentUser({ ...currentUser, email: newVal })}
                            />
                        </>
                    )}
                    {![2, 3].includes(currentUser.idRole) && (
                        <SingleSelect
                            inputLabel="ruolo"
                            placeholder="Seleziona un ruolo"
                            value={getUserRoleObject(currentUser.idRole)}
                            options={getAvailableRoles()}
                            onValueChange={(newOpt) => {
                                if (!newOpt) return
                                setCurrentUser({ ...currentUser, idRole: parseInt(newOpt.value, 10) })
                            }}
                        />
                    )}
                </div>
                <div className="modal-component__footer">
                    <Button buttonType="secondary" onClick={() => onClose()}>
                        Annulla
                    </Button>
                    <Button
                        buttonType="primary"
                        disabled={isLoading || dataIsNotValid || [2, 3].includes(currentUser.idRole)}
                        onClick={() => {
                            if (user) void updateSubUser()
                            else void createUser()
                        }}
                    >
                        {user ? 'Aggiorna' : 'Crea'}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default CreateUserModal
