import React, { useEffect, useState } from 'react'
import Button from '../../../components/Buttons/Button'
import { useAppSelector } from '../../../store/reducers/store'
import {
    getActiveIcooneStartUpload,
    getRawIcooneStartMatrix,
    importIcooneStartMatrix,
} from '../../../resources/api-constants'
import ImportFileModal from '../../../components/Modal/ImportFileModal'
import Paragraph from '../../../components/Typography/Paragraph'
import { ToastError, ToastSuccess } from '../../../utility/toast'
import { AxiosError } from 'axios'
import { useInterval } from '../../../utility/hooks/common'

const IcooneStartPage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const applicationTypes = useAppSelector((data) => data.data.appTypes)
    const [resetFlag, setResetFlag] = useState(false)
    const [showImportModal, setShowImportModal] = useState(false)
    const [downloadTypeInLoading, setDownloadTypeInLoading] = useState<number | null>(null)
    const [importIsLoading, setImportIsLoading] = useState(false)
    const [importIsInProgress, setImportIsInProgress] = useState(false)

    useEffect(() => {
        if (resetFlag) setResetFlag(!resetFlag)
    }, [resetFlag])

    useInterval(async () => {
        const activeUpload = await getActiveIcooneStartUpload(user.loggedUserData?.authToken || '')

        if (activeUpload && activeUpload.id !== undefined) {
            setImportIsInProgress(true)
        } else {
            setImportIsInProgress(false)
        }
    }, 5000)

    const getStartingModalFile = async (idAppType: number) => {
        try {
            setDownloadTypeInLoading(idAppType)
            const rawFileData = await getRawIcooneStartMatrix(user.loggedUserData?.authToken || '', idAppType)

            const appType = applicationTypes.find((appType) => appType.id === idAppType)
            if (!rawFileData || !appType) return

            const link = document.createElement('a')
            link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${rawFileData}`
            link.setAttribute(
                'download',
                `Matrice_icoone_start_${new Date().toLocaleDateString().replaceAll('/', '_')}_${appType?.name}.xlsx`
            )
            document.body.appendChild(link)
            link.click()
        } catch (error) {
            console.error(error)
        }
        setDownloadTypeInLoading(null)
    }

    const startImportProcedure = async (file: any) => {
        try {
            setImportIsLoading(true)
            setImportIsInProgress(true)
            const res = await importIcooneStartMatrix(user.loggedUserData?.authToken || '', file).then(
                (response) => response
            )
            if (res) setShowImportModal(false)
            ToastSuccess('Matrice importata con successo')
        } catch (error) {
            console.error(error)
            ToastError((error as any).response?.data.message || (error as AxiosError).message)
        }
        setImportIsLoading(false)
    }

    return (
        <>
            {showImportModal && (
                <ImportFileModal
                    modalTitle="Importa matrice"
                    modalMessage="Seleziona il file con estensione .xlsx da importare."
                    acceptedFileType=".xlsx"
                    loading={importIsLoading}
                    onFileImport={(file: any) => void startImportProcedure(file)}
                    onClose={() => setShowImportModal(false)}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">Matrice iCOONE start</span>
                </div>
            </div>
            <div className="page-content-block">
                <div className="elevated-card full-width">
                    <div className="text-paragraphs-container">
                        <Paragraph>
                            Gestisci l&apos;aggiornamento massivo dei protocolli ed il suggerimento di tali elementi
                            durante il processo di iCOONE start relativo ad un nuovo trattamento.
                        </Paragraph>
                        <Paragraph>
                            Ricordati di mantenere lo stesso formato della matrice appena scaricata e di modificare il
                            foglio di calcolo aggiungendo solamente le X relativamente ai valori desiderati.
                        </Paragraph>
                        <span>
                            Per cominciare: assicurati di aver creato i protocolli necessari nell&apos;apposita sezione;
                            una volta creati torna su questa pagina e clicca su &quot;Scarica matrice attiva&quot;.
                            Aggiorna la matrice e importala nel sistema cliccando su &quot;Importa matrice&quot;.
                        </span>
                    </div>
                    <div className="items-row">
                        <Button
                            buttonType="secondary"
                            onClick={() => setShowImportModal(true)}
                            disabled={importIsInProgress}
                        >
                            Importa matrice
                        </Button>
                        {importIsInProgress && (
                            <div className="items-row">
                                <span className="text-paragraphs">Una matrice è in fase di importazione...</span>
                            </div>
                        )}
                    </div>
                </div>
                <div style={{ height: 16 }} />
                <div className="items-row full-width">
                    {applicationTypes.map((applicationType) => (
                        <div key={applicationType.id} className="elevated-card">
                            <div style={{ alignItems: 'center' }} className="text-paragraphs-container full-width">
                                <Paragraph>
                                    <span className="page-title">{applicationType.name}</span>
                                </Paragraph>
                            </div>
                            <Button
                                buttonType="primary"
                                loading={downloadTypeInLoading === applicationType.id}
                                onClick={() => void getStartingModalFile(applicationType.id)}
                                disabled={importIsInProgress}
                            >
                                Scarica matrice attiva
                            </Button>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default IcooneStartPage
