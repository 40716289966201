/* eslint-disable @typescript-eslint/no-duplicate-enum-values */

export enum ROUTES {
    HOMEPAGE_ROUTE = '/',
    CENTERS_ROUTE = '/centers',
    CENTER_DETAIL_ROUTE = '/centers/',
    DEALERS_ROUTE = '/dealers',
    DEALER_DETAIL_ROUTE = '/dealers/',
    RESULTS_ROUTE = '/results',
    RESULT_DETAIL_ROUTE = '/results/',
    ANALYTICS_ROUTE = '/analytics',
    ICOONE_TRAINING_MEDIA_ROUTE = '/training-media',
    ICOONE_MARKETING_MEDIA_ROUTE = '/marketing-media',
    ICOONE_AFTER_SALES_MEDIA_ROUTE = '/after-sales-media',
    ICOONE_MEDIA_DETAIL_ROUTE = '/icoone-media-detail/',
    ICOONE_MEDIA_FOLDER_DETAIL_ROUTE = '/icoone-media-folder-detail/',
    PROTOCOLS_ROUTE = '/protocols',
    PROTOCOL_DETAIL_ROUTE = '/protocols/',
    BADGES_ROUTE = '/badges',
    BADGE_DETAIL_ROUTE = '/badges/',
    NOTIFICATIONS_ROUTE = '/notifications',

    CERTIFICATIONS_ROUTE = '/certifications',
    CERTIFICATION_DETAIL_ROUTE = '/certifications/',
    DEVICES_ROUTE = '/devices',
    DEVICE_DETAIL_ROUTE = '/devices/',
    COMMERCIAL_AREA_ROUTE = '/commercial-area',
    ICOONE_START_ROUTE = '/icoone-start',

    FAQS_ROUTE = '/faqs',
    FAQ_DETAIL_ROUTE = '/faq-detail/',

    SERIALS_ROUTE = '/serials/',

    LOGIN_ROUTE = '/',
    REQUEST_RESET_PASSWORD_ROUTE = '/request-reset-password',
    RESET_PASSWORD_ROUTE = '/reset-password',
}
