import React, { useEffect, useState } from 'react'
import './modals.sass'
import Button from '../Buttons/Button'
import CircularLoader from '../InfiniteLoader/CircularLoader'
import { useAppSelector } from '../../store/reducers/store'
import { createSingleDirectory, updateSingleDirectory } from '../../resources/api-constants'
import { ToastError, ToastSuccess } from '../../utility/toast'
import { MediaDirectory, MediaDirectoryToCreate, MediaVisibility } from '../../types/data'
import TextInput from '../Inputs/TextInput'
import { TargetEntity } from '../../resources/other-constants'
import SingleSelect from '../Inputs/SingleSelect'
import TranslationModal from './TranslationModal'

const defaultDirectory: MediaDirectory = {
    id: '',
    name: '',
    creationDate: '',
    updateDate: '',
    idMediaScope: '',
    visibility: MediaVisibility.all,
    localized: { it: { name: '' } },
}

const FolderDetailModal: React.FC<{
    mediaDirectory?: MediaDirectory
    idMediaScope: string
    idParentDirectory?: string
    onClose: (success?: boolean) => void
}> = ({ mediaDirectory, idMediaScope, idParentDirectory, onClose }) => {
    const user = useAppSelector((data) => data.user)
    const [directory, setDirectory] = useState<MediaDirectory>({ ...defaultDirectory, idMediaScope })
    const [isLoading, setIsLoading] = useState(false)
    const [isLocalizationVisible, setIsLocalizationVisible] = useState(false)

    useEffect(() => {
        if (mediaDirectory) setDirectory(mediaDirectory)
    }, [mediaDirectory])

    const startCreateProcedure = async () => {
        try {
            setIsLoading(true)
            let data: MediaDirectoryToCreate = directory
            if (idParentDirectory) {
                data = { ...directory, idMediaDirectory: parseInt(idParentDirectory, 10) }
            }
            const res = await createSingleDirectory(user.loggedUserData?.authToken || '', data).then(
                (response) => response
            )
            if (res) onClose(true)
            ToastSuccess('Cartella creata con successo')
        } catch (error) {
            console.error(error)
            ToastError('Errore durante la procedura di creazione')
        }
        setIsLoading(false)
    }

    const startUpdateProcedure = async () => {
        try {
            setIsLoading(true)
            const res = await updateSingleDirectory(user.loggedUserData?.authToken || '', directory).then(
                (response) => response
            )
            if (res) onClose(true)
            ToastSuccess('Cartella aggiornata con successo')
        } catch (error) {
            console.error(error)
            ToastError('Errore durante la procedura di aggiornamento')
        }
        setIsLoading(false)
    }

    return (
        <>
            {isLocalizationVisible && (
                <TranslationModal
                    modalTitle="Gestione traduzioni"
                    attributesSchema={directory.localized}
                    onClose={() => setIsLocalizationVisible(false)}
                    onConfirm={(localized) => {
                        setDirectory({ ...directory, localized })
                        setIsLocalizationVisible(false)
                    }}
                />
            )}
            <div className="modal-wrapper" style={{ zIndex: 9998 }}>
                <div className="dark-cover" />

                <div className="modal-component">
                    <div className="modal-component__header">
                        <span className="page-title">
                            {mediaDirectory ? `Modifica ${mediaDirectory.localized.it.name}` : 'Creazione nuova cartella'}
                        </span>
                    </div>
                    <div className="modal-component__body">
                        {isLoading ? (
                            <CircularLoader />
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 8,
                                    paddingBottom: 48,
                                }}
                            >
                                <TextInput
                                    placeholder="Nome cartella"
                                    value={directory?.localized.it.name || ''}
                                    onValueChange={(e) => {
                                        const local = JSON.parse(JSON.stringify(directory.localized))
                                        local.it.name = e
                                        setDirectory({ ...directory, localized: local })
                                    }}
                                />
                                <SingleSelect
                                    inputLabel="Visibilità"
                                    placeholder="Seleziona una tipologia di utenti"
                                    options={TargetEntity}
                                    value={TargetEntity.find((i) => i.value === directory.visibility)}
                                    onValueChange={(selectedOption) =>
                                        setDirectory({
                                            ...directory,
                                            visibility: selectedOption?.value || MediaVisibility.all,
                                        })
                                    }
                                />
                            </div>
                        )}
                    </div>
                    <div className="modal-component__footer">
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                flexDirection: 'row',
                                width: '100%',
                            }}
                        >
                            <div>
                                <Button
                                    disabled={isLoading || directory.localized.it.name === ''}
                                    buttonType="secondary"
                                    onClick={() => setIsLocalizationVisible(true)}
                                >
                                    Gestione traduzioni
                                </Button>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    flexDirection: 'row',
                                    gap: 8,
                                }}
                            >
                                <Button buttonType="secondary" disabled={isLoading} onClick={() => onClose()}>
                                    Annulla
                                </Button>
                                <Button
                                    buttonType="primary"
                                    disabled={isLoading || !directory.localized.it.name || !directory.visibility}
                                    onClick={() => {
                                        mediaDirectory ? void startUpdateProcedure() : void startCreateProcedure()
                                    }}
                                >
                                    {mediaDirectory ? 'Modifica' : 'Crea'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FolderDetailModal
