/* eslint-disable indent */
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../resources/routes-constants'
import Button from '../../components/Buttons/Button'
import TextInput from '../../components/Inputs/TextInput'
import TextareaInput from '../../components/Inputs/TextareaInput'
import MultiSelect, { SelectOption } from '../../components/Inputs/MultiSelect'
import UppercaseLabel from '../../components/Typography/UppercaseLabel'
import { formatItemForSelectOptions, getElementIdFromCurrentRoute } from '../../utility/functions'
import { ToastError, ToastSuccess } from '../../utility/toast'
import { CustomerGender, Device, InfoItem, Protocol, ProtocolToEdit, SimpleEntity } from '../../types/data'
import {
    createSingleProtocol,
    deleteSingleProtocol,
    getSingleProtocol,
    updateSingleProtocol,
} from '../../resources/api-constants'
import { useAppSelector } from '../../store/reducers/store'
import SingleSelect from '../../components/Inputs/SingleSelect'
import AlertModal from '../../components/Modal/AlertModal'
import TranslationModal from '../../components/Modal/TranslationModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWarning } from '@fortawesome/free-solid-svg-icons'
import TextLabel from '../../components/Badges/TextLabel'

const defaultTranslations = { it: { description: '' } }

const emptyProtocol: ProtocolToEdit = {
    id: '-1',
    name: '',
    description: '',
    idSessionDuration: null,
    idSessionsNumber: null,
    idFrequency: null,
    localized: defaultTranslations,
    idApplicationType: -1,
    associations: {
        priorityIntensities: [],
        lifeMoments: [],
        phisicalActivities: [],
        skinTypes: [],
        devices: [],
        bodyAreas: [],
        goals: [],
        priorities: [],
        customerGenders: [],
        medicalGroups: [],
        medicalProblems: [],
    },
}

const ProtocolDetailPage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const data = useAppSelector((data) => data.data)
    const applicationTypes = useAppSelector((data) => data.data.appTypes)
    const [fetchedProtocolName, setFetchedProtocolName] = useState('')
    const [protocol, setProtocol] = useState<ProtocolToEdit>(emptyProtocol)
    const [protocolApplicationType, setProtocolApplicationType] = useState<number>(-1)
    const [selectedGoalsIds, setSelectedGoalsIds] = useState<string[]>([])
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLocalizationVisible, setIsLocalizationVisible] = useState(false)
    const currentProtocolId = getElementIdFromCurrentRoute(window.location.pathname)
    const navigate = useNavigate()

    const parseProtocol = (protocol: Protocol): ProtocolToEdit => {
        return {
            name: protocol.name,
            description: protocol.description,
            idSessionDuration: protocol.sessionDuration?.id || null,
            idSessionsNumber: protocol.sessionsNumber?.id || null,
            localized: protocol.localized,
            idFrequency: protocol.frequency?.id || null,
            idApplicationType: protocol.applicationType.id,
            associations: {
                priorityIntensities: protocol.priorityIntensities.map((priorityIntensity) =>
                    parseInt(priorityIntensity.id, 10)
                ),
                lifeMoments: protocol.lifeMoments.map((lifeMoment) => lifeMoment.id),
                phisicalActivities: protocol.phisicalActivities.map((phisicalActivity) => phisicalActivity.id),
                skinTypes: protocol.skinTypes.map((skinType) => skinType.id),

                devices: protocol.devices.map((device) => device.id),
                goals: protocol.goals.map((goal) => goal.id),
                priorities: protocol.priorities.map((priority) => priority.id),
                bodyAreas: protocol.bodyAreas.map((bodyArea) => bodyArea.id),
                customerGenders: protocol.customerGenders.map((gender) => gender.id),
                medicalGroups: protocol.medicalGroups.map((group) => group.id),
                medicalProblems: protocol.medicalProblems.map((problem) => problem.id),
            },
            creationDate: protocol.creationDate,
            updateDate: protocol.updateDate,
        }
    }

    const fetchProtocolData = async () => {
        try {
            setIsLoading(true)
            const fetchedProtocol = await getSingleProtocol(user.loggedUserData?.authToken || '', currentProtocolId)
            if (fetchedProtocol) {
                const parsedProtocol = parseProtocol(fetchedProtocol)

                setFetchedProtocolName(fetchedProtocol.name)
                setProtocolApplicationType(fetchedProtocol.applicationType.id)
                setProtocol(parsedProtocol)
                setSelectedGoalsIds(fetchedProtocol.goals.map((goal) => goal.id.toString()))
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante il recupero dei dati!')
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (currentProtocolId !== '-1') void fetchProtocolData()
    }, [currentProtocolId])

    const startDeleteProcedure = () => {
        setShowDeleteModal(true)
    }

    const endRemoveProtocolProcedure = () => {
        setShowDeleteModal(false)
        ToastSuccess('Protocollo rimosso con successo')
        navigate(ROUTES.PROTOCOLS_ROUTE)
    }

    const removeProtocol = async () => {
        try {
            setIsLoading(true)
            const res = await deleteSingleProtocol(user.loggedUserData?.authToken || '', currentProtocolId)
            if (!res) ToastError('Si è verificato un errore durante la procedura di eliminazione!')
            else {
                endRemoveProtocolProcedure()
            }
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }

    const startCreateProtocolProcedure = async () => {
        try {
            setIsLoading(true)
            const data: ProtocolToEdit = {
                ...protocol,
                idApplicationType: protocolApplicationType,
                associations: {
                    ...protocol.associations,
                },
            }
            delete data.id
            const res = await createSingleProtocol(user.loggedUserData?.authToken || '', data)
            if (res) {
                ToastSuccess('Protocollo creato con successo')
                navigate(`${ROUTES.PROTOCOL_DETAIL_ROUTE}${res.id}`)
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di creazione!')
        }
        setIsLoading(false)
    }

    const startUpdateProtocolProcedure = async () => {
        try {
            setIsLoading(true)
            const newProtocol: ProtocolToEdit = {
                ...protocol,
                idApplicationType: protocolApplicationType,
                associations: {
                    ...protocol.associations,
                },
            }

            const res = await updateSingleProtocol(user.loggedUserData?.authToken || '', currentProtocolId, newProtocol)
            if (res) {
                ToastSuccess('Protocollo aggiornato con successo')
                await fetchProtocolData()
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di aggiornamento!')
        }
        setIsLoading(false)
    }

    const getAppTypeLabel = useMemo(() => {
        const currentApplicationType = applicationTypes.find((appType) => appType.id === protocolApplicationType)
        if (!currentApplicationType) return

        return (
            <TextLabel key={currentApplicationType.id} customColor={currentApplicationType.color}>
                {currentApplicationType.name}
            </TextLabel>
        )
    }, [applicationTypes, protocolApplicationType])

    const dataIsNotValid = useMemo((): boolean => {
        return (
            !protocol.name ||
            !protocol.localized ||
            Object.keys(protocol.localized).length < 2 ||
            protocolApplicationType === -1
        )
    }, [protocol.name, protocol.localized, protocolApplicationType])

    const protocolIsNotConfigured = useMemo(() => {
        const beautyEmptyAssociations =
            protocol.associations.skinTypes.length === 0 ||
            protocol.associations.priorityIntensities.length === 0 ||
            protocol.associations.lifeMoments.length === 0 ||
            protocol.associations.phisicalActivities.length === 0 ||
            protocol.associations.bodyAreas.length === 0 ||
            protocol.associations.priorities.length === 0 ||
            protocol.associations.goals.length === 0 ||
            protocol.associations.devices.length === 0 ||
            !protocol.idSessionsNumber === null ||
            !protocol.idSessionDuration === null ||
            protocol.idFrequency === null
        const medicalEmptyAssociations =
            protocol.associations.medicalGroups.length === 0 ||
            protocol.associations.medicalProblems.length === 0 ||
            protocol.associations.devices.length === 0 ||
            protocol.associations.customerGenders.length === 0
        const wellnessEmptyAssociations =
            protocol.associations.devices.length === 0 ||
            protocol.associations.customerGenders.length === 0 ||
            protocol.associations.lifeMoments.length === 0 ||
            protocol.associations.goals.length === 0
        const currentEmptyAssociations =
            protocolApplicationType === 1
                ? beautyEmptyAssociations
                : protocolApplicationType === 2
                ? wellnessEmptyAssociations
                : medicalEmptyAssociations
        return (
            currentEmptyAssociations ||
            !protocol.localized ||
            Object.keys(protocol.localized).length < 2 ||
            protocolApplicationType === -1
        )
    }, [protocol, protocolApplicationType])

    return (
        <>
            {isLocalizationVisible && (
                <TranslationModal
                    modalTitle="Gestione traduzioni"
                    attributesSchema={protocol.localized}
                    onClose={() => setIsLocalizationVisible(false)}
                    onConfirm={(localized) => {
                        setProtocol({ ...protocol, localized })
                        setIsLocalizationVisible(false)
                    }}
                />
            )}
            {showDeleteModal && (
                <AlertModal
                    modalTitle="Elimina protocollo"
                    modalMessage={`Sei sicuro di voler eliminare l'elemento ${
                        fetchedProtocolName || ''
                    }? Questa operazione è irreversibile.`}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => void removeProtocol()}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">
                        <Link to={ROUTES.PROTOCOLS_ROUTE}>Elenco protocolli</Link> /{' '}
                        {currentProtocolId === '-1' ? 'Nuovo protocollo' : fetchedProtocolName || ''}
                    </span>
                </div>
                <div className="page-header-section__right-box">
                    <Button disabled={isLoading} buttonType="secondary" onClick={() => setIsLocalizationVisible(true)}>
                        Gestione traduzioni
                    </Button>
                    <Button
                        disabled={isLoading}
                        buttonType="secondary"
                        onClick={() => navigate(ROUTES.PROTOCOLS_ROUTE)}
                    >
                        Annulla
                    </Button>
                    {currentProtocolId !== '-1' && (
                        <Button
                            disabled={isLoading}
                            buttonType="secondary-error"
                            onClick={() => void startDeleteProcedure()}
                        >
                            Elimina
                        </Button>
                    )}
                    <Button
                        loading={isLoading}
                        disabled={
                            currentProtocolId === '-1' ? dataIsNotValid : dataIsNotValid || protocolIsNotConfigured
                        }
                        buttonType="primary"
                        onClick={() => {
                            if (currentProtocolId === '-1') {
                                void startCreateProtocolProcedure()
                            } else {
                                void startUpdateProtocolProcedure()
                            }
                        }}
                    >
                        {currentProtocolId === '-1' ? 'Aggiungi protocollo' : 'Aggiorna protocollo'}
                    </Button>
                </div>
            </div>
            <div className="page-content-flow">
                <div className="elevated-card full-width">
                    <div className="input-form-box">
                        <div className="input-form-box__three-col-row">
                            <TextInput
                                inputLabel="nome"
                                value={protocol.name}
                                onValueChange={(newVal) => setProtocol({ ...protocol, name: newVal })}
                            />
                            {(!protocol.localized || Object.keys(protocol.localized).length < 2) && (
                                <div style={{ marginLeft: 6 }}>
                                    <div style={{ marginBottom: 6 }}>
                                        <UppercaseLabel>Traduzioni</UppercaseLabel>
                                    </div>
                                    <div>
                                        Genera o modifica le traduzioni dei campi di questo protocollo per poter{' '}
                                        <b>salvare le modifiche</b>.
                                    </div>
                                </div>
                            )}
                            {protocol.id !== '-1' && (
                                <>
                                    <div style={{ marginLeft: 6 }}>
                                        <div style={{ marginBottom: 6 }}>
                                            <UppercaseLabel>Date</UppercaseLabel>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ display: 'flex', gap: 4 }}>
                                                Creazione:{' '}
                                                <b>{new Date(protocol.creationDate || '').toLocaleString()}</b>
                                            </span>
                                            <span style={{ display: 'flex', gap: 4 }}>
                                                Ultima modifica:
                                                <b>{new Date(protocol.updateDate || '').toLocaleString()}</b>
                                            </span>
                                        </div>
                                    </div>
                                    {protocolIsNotConfigured && (
                                        <div style={{ marginLeft: 6 }}>
                                            <div
                                                style={{
                                                    marginBottom: 6,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faWarning} color="orange" />
                                                <UppercaseLabel>Conformità</UppercaseLabel>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                Questo protocollo non è ancora configurato correttamente per essere
                                                riportato in un risultato o trattamento.
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        <div className="input-form-box__row-full-width">
                            <TextareaInput
                                inputLabel="descrizione"
                                value={protocol?.localized.it.description || ''}
                                onValueChange={(newVal) => {
                                    const local = JSON.parse(JSON.stringify(protocol.localized))
                                    local.it.description = newVal
                                    setProtocol({ ...protocol, localized: local })
                                }}
                            />
                        </div>
                        <div className="horizontal-line-separator" />
                        <div className="input-form-box__three-col-row">
                            {protocol.id === '-1' ? (
                                <SingleSelect
                                    inputLabel="Tipologia applicazione"
                                    placeholder="Seleziona una tipologia applicazione"
                                    options={applicationTypes.map((appType) => ({
                                        value: appType.id.toString(),
                                        label: appType.name,
                                    }))}
                                    onValueChange={(newOpt) => {
                                        if (!newOpt) return
                                        setProtocolApplicationType(parseInt(newOpt.value, 10))
                                    }}
                                />
                            ) : (
                                <div style={{ marginLeft: 6 }}>
                                    <div style={{ marginBottom: 6 }}>
                                        <UppercaseLabel>Tipologia applicazione</UppercaseLabel>
                                    </div>
                                    <div style={{ display: 'flex', gap: 8 }}>{getAppTypeLabel}</div>
                                </div>
                            )}
                            {protocolApplicationType === 1 && (
                                <MultiSelect
                                    inputLabel="Area del corpo"
                                    placeholder="Seleziona una o più aree"
                                    values={data.bodyAreas
                                        .filter(
                                            (bodyArea: InfoItem) =>
                                                protocol.associations.bodyAreas.indexOf(bodyArea.id) > -1
                                        )
                                        .map((bodyArea) => ({ value: bodyArea.id.toString(), label: bodyArea.name }))}
                                    options={data.bodyAreas.map((bodyArea) => {
                                        return { value: bodyArea.id.toString(), label: bodyArea.name }
                                    })}
                                    onValueChange={(newVal) => {
                                        const fixedNewValues = newVal as SelectOption[]
                                        setProtocol({
                                            ...protocol,
                                            associations: {
                                                ...protocol.associations,
                                                bodyAreas: [
                                                    ...fixedNewValues.map((value) => parseInt(value.value, 10)),
                                                ],
                                            },
                                        })
                                    }}
                                />
                            )}
                            {[1, 2].includes(protocolApplicationType) && (
                                <MultiSelect
                                    inputLabel="Obiettivi da raggiungere"
                                    placeholder="Seleziona uno o più obiettivi"
                                    values={data.goals
                                        .filter((goal: InfoItem) => protocol.associations.goals.indexOf(goal.id) > -1)
                                        .map((goal) => ({ value: goal.id.toString(), label: goal.name }))}
                                    options={data.goals
                                        .filter((g) =>
                                            g.applicationTypes
                                                .map((gAppType) => gAppType.id)
                                                .includes(protocolApplicationType)
                                        )
                                        .map((goal) => {
                                            return { value: goal.id.toString(), label: goal.name }
                                        })}
                                    onValueChange={(newVal) => {
                                        const fixedNewValues = newVal as SelectOption[]
                                        setProtocol({
                                            ...protocol,
                                            associations: {
                                                ...protocol.associations,
                                                goals: [...fixedNewValues.map((value) => parseInt(value.value, 10))],
                                            },
                                        })
                                        setSelectedGoalsIds([
                                            ...selectedGoalsIds,
                                            ...fixedNewValues.map((value) => value.value),
                                        ])
                                    }}
                                />
                            )}
                            {protocolApplicationType === 1 && (
                                <MultiSelect
                                    inputLabel="Priorità"
                                    placeholder="Seleziona uno o più priorità"
                                    disabled={protocol.associations.goals.length === 0}
                                    values={data.priorities
                                        .filter(
                                            (problem: InfoItem) =>
                                                protocol.associations.priorities.indexOf(problem.id) > -1
                                        )
                                        .map((prob) => ({ value: prob.id.toString(), label: prob.name }))}
                                    options={data.priorities
                                        .filter((problem) => selectedGoalsIds.indexOf(problem.goal.id.toString()) > -1)
                                        .map((problem) => {
                                            return { value: problem.id.toString(), label: problem.name }
                                        })}
                                    onValueChange={(newVal) => {
                                        const fixedNewValues = newVal as SelectOption[]
                                        setProtocol({
                                            ...protocol,
                                            associations: {
                                                ...protocol.associations,
                                                priorities: [
                                                    ...fixedNewValues.map((value) => parseInt(value.value, 10)),
                                                ],
                                            },
                                        })
                                    }}
                                />
                            )}
                            {protocolApplicationType === 3 && (
                                <>
                                    <MultiSelect
                                        inputLabel="Gruppo medicale"
                                        placeholder="Seleziona uno o più gruppi medicali"
                                        values={data.medicalGroups
                                            .filter(
                                                (problem: InfoItem) =>
                                                    protocol.associations.medicalGroups.indexOf(problem.id) > -1
                                            )
                                            .map((prob) => ({ value: prob.id.toString(), label: prob.name }))}
                                        options={data.medicalGroups.map((problem) => {
                                            return { value: problem.id.toString(), label: problem.name }
                                        })}
                                        onValueChange={(newVal) => {
                                            const fixedNewValues = newVal as SelectOption[]
                                            setProtocol({
                                                ...protocol,
                                                associations: {
                                                    ...protocol.associations,
                                                    medicalGroups: [
                                                        ...fixedNewValues.map((value) => parseInt(value.value, 10)),
                                                    ],
                                                },
                                            })
                                        }}
                                    />
                                    <MultiSelect
                                        inputLabel="Problema medicale"
                                        placeholder="Seleziona uno o più problemi medicali"
                                        values={data.medicalProblems
                                            .filter(
                                                (problem: InfoItem) =>
                                                    protocol.associations.medicalProblems.indexOf(problem.id) > -1
                                            )
                                            .map((prob) => ({ value: prob.id.toString(), label: prob.name }))}
                                        options={data.medicalProblems.map((problem) => {
                                            return { value: problem.id.toString(), label: problem.name }
                                        })}
                                        onValueChange={(newVal) => {
                                            const fixedNewValues = newVal as SelectOption[]
                                            setProtocol({
                                                ...protocol,
                                                associations: {
                                                    ...protocol.associations,
                                                    medicalProblems: [
                                                        ...fixedNewValues.map((value) => parseInt(value.value, 10)),
                                                    ],
                                                },
                                            })
                                        }}
                                    />
                                </>
                            )}
                            <MultiSelect
                                inputLabel="Dispositivi compatibili"
                                placeholder="Seleziona uno o più dispositivi"
                                values={data.devices
                                    .filter((genericDevice: Device) =>
                                        protocol.associations.devices.some((deviceId) => deviceId === genericDevice.id)
                                    )
                                    .map((device) => ({ value: device.id.toString(), label: device.name }))}
                                options={data.devices.map((device) => {
                                    return { value: device.id.toString(), label: device.name }
                                })}
                                onValueChange={(newVal) => {
                                    const fixedNewValues = newVal as SelectOption[]
                                    setProtocol({
                                        ...protocol,
                                        associations: {
                                            ...protocol.associations,
                                            devices: [...fixedNewValues.map((value) => parseInt(value.value, 10))],
                                        },
                                    })
                                }}
                            />
                        </div>
                        <div className="horizontal-line-separator" />
                        <div className="input-form-box__three-col-row">
                            <MultiSelect
                                inputLabel="genere"
                                placeholder="Seleziona il genere"
                                values={data.customerGenders
                                    .filter(
                                        (gender: CustomerGender) =>
                                            protocol.associations.customerGenders.indexOf(gender.id) > -1
                                    )
                                    .map((gender) => ({ value: gender.id.toString(), label: gender.name }))}
                                options={data.customerGenders.map((customerGender) => ({
                                    value: customerGender.id.toString(),
                                    label: customerGender.name,
                                }))}
                                onValueChange={(newValues) => {
                                    if (!newValues) return
                                    setProtocol({
                                        ...protocol,
                                        associations: {
                                            ...protocol.associations,
                                            customerGenders: newValues.map((newVal) => parseInt(newVal.value, 10)),
                                        },
                                    })
                                }}
                            />
                            {[1].includes(protocolApplicationType) && (
                                <MultiSelect
                                    inputLabel="periodo della vita"
                                    placeholder="Seleziona periodo della vita"
                                    values={data.lifeMoments
                                        .filter(
                                            (item: InfoItem) => protocol.associations.lifeMoments.indexOf(item.id) > -1
                                        )
                                        .map((item) => ({ value: item.id.toString(), label: item.name }))}
                                    options={data.lifeMoments.map((item) => ({
                                        value: item.id.toString(),
                                        label: item.name,
                                    }))}
                                    onValueChange={(newValues) => {
                                        if (!newValues) return
                                        setProtocol({
                                            ...protocol,
                                            associations: {
                                                ...protocol.associations,
                                                lifeMoments: newValues.map((newVal) => parseInt(newVal.value, 10)),
                                            },
                                        })
                                    }}
                                />
                            )}
                            {protocolApplicationType === 1 && (
                                <>
                                    <MultiSelect
                                        inputLabel="Livello attività fisica"
                                        placeholder="Seleziona il livello"
                                        values={data.phisicalActivities
                                            .filter(
                                                (item: InfoItem) =>
                                                    protocol.associations.phisicalActivities.indexOf(item.id) > -1
                                            )
                                            .map((item) => ({ value: item.id.toString(), label: item.name }))}
                                        options={data.phisicalActivities.map((item) => ({
                                            value: item.id.toString(),
                                            label: item.name,
                                        }))}
                                        onValueChange={(newValues) => {
                                            if (!newValues) return
                                            setProtocol({
                                                ...protocol,
                                                associations: {
                                                    ...protocol.associations,
                                                    phisicalActivities: newValues.map((newVal) =>
                                                        parseInt(newVal.value, 10)
                                                    ),
                                                },
                                            })
                                        }}
                                    />
                                    <MultiSelect
                                        inputLabel="pelle al momento dell'esame fisico"
                                        placeholder="Seleziona un tipo di pelle"
                                        values={data.skinTypes
                                            .filter(
                                                (item: InfoItem) =>
                                                    protocol.associations.skinTypes.indexOf(item.id) > -1
                                            )
                                            .map((item) => ({ value: item.id.toString(), label: item.name }))}
                                        options={data.skinTypes.map((item) => ({
                                            value: item.id.toString(),
                                            label: item.name,
                                        }))}
                                        onValueChange={(newValues) => {
                                            if (!newValues) return
                                            setProtocol({
                                                ...protocol,
                                                associations: {
                                                    ...protocol.associations,
                                                    skinTypes: newValues.map((newVal) => parseInt(newVal.value, 10)),
                                                },
                                            })
                                        }}
                                    />
                                    <MultiSelect
                                        inputLabel="intensità problema"
                                        placeholder="Seleziona un livello di intensità"
                                        values={data.priorityIntensities
                                            .filter(
                                                (item: InfoItem) =>
                                                    protocol.associations.priorityIntensities.indexOf(item.id) > -1
                                            )
                                            .map((item) => ({ value: item.id.toString(), label: item.name }))}
                                        options={data.priorityIntensities.map((item) => ({
                                            value: item.id.toString(),
                                            label: item.name,
                                        }))}
                                        onValueChange={(newValues) => {
                                            if (!newValues) return
                                            setProtocol({
                                                ...protocol,
                                                associations: {
                                                    ...protocol.associations,
                                                    priorityIntensities: newValues.map((newVal) =>
                                                        parseInt(newVal.value, 10)
                                                    ),
                                                },
                                            })
                                        }}
                                    />
                                </>
                            )}
                        </div>
                        {[1, 2].includes(protocolApplicationType) && (
                            <>
                                <div className="horizontal-line-separator" />
                                <div className="input-form-box__three-col-row">
                                    {[1].includes(protocolApplicationType) && (
                                        <SingleSelect
                                            inputLabel="numero di sessioni"
                                            placeholder="Seleziona il numero di sessioni"
                                            value={formatItemForSelectOptions<SimpleEntity>(
                                                data.sessionsNumbers.find(
                                                    (sessionNumber) => sessionNumber.id === protocol.idSessionsNumber
                                                )
                                            )}
                                            options={data.sessionsNumbers.map((sessionNumber) => ({
                                                value: sessionNumber.id,
                                                label: sessionNumber.value,
                                            }))}
                                            onValueChange={(newVal) => {
                                                if (!newVal) return
                                                setProtocol({
                                                    ...protocol,
                                                    idSessionsNumber: newVal.value,
                                                })
                                            }}
                                        />
                                    )}
                                    <SingleSelect
                                        inputLabel="Durata sessione (minuti)"
                                        placeholder="Seleziona la durata delle sessioni"
                                        options={data.sessionsDurations.map((sessionsDuration) => ({
                                            value: sessionsDuration.id,
                                            label: sessionsDuration.value,
                                        }))}
                                        value={formatItemForSelectOptions<SimpleEntity>(
                                            data.sessionsDurations.find(
                                                (sessionsDuration) => sessionsDuration.id === protocol.idSessionDuration
                                            )
                                        )}
                                        onValueChange={(newVal) => {
                                            if (!newVal) return
                                            setProtocol({
                                                ...protocol,
                                                idSessionDuration: newVal.value,
                                            })
                                        }}
                                    />
                                    {[1].includes(protocolApplicationType) && (
                                        <SingleSelect
                                            inputLabel="frequenza trattamento"
                                            placeholder="Seleziona la frequenza per un trattamento"
                                            options={data.frequencies.map((frequency) => ({
                                                label: frequency.name ?? '',
                                                value: frequency.id,
                                            }))}
                                            value={formatItemForSelectOptions<SimpleEntity>(
                                                data.frequencies.find(
                                                    (frequency) => frequency.id === protocol.idFrequency
                                                )
                                            )}
                                            onValueChange={(newVal) => {
                                                if (!newVal) return
                                                setProtocol({
                                                    ...protocol,
                                                    idFrequency: newVal.value,
                                                })
                                            }}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProtocolDetailPage
