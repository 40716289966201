import { useEffect, useRef } from 'react'

/**
 * Mantiene lo stato precendente del valore passato
 * @param value any
 */
export const usePrevious = (value: any): any => {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

/**
 * Hook that manage the interval
 * @param callback Callback function to execute in the loop
 * @param delay Delay time
 */
export const useInterval = (callback: any, delay: number): void => {
    const savedCallback = useRef<any>(null)

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        const tick = (): void => {
            savedCallback.current()
        }

        if (delay !== null) {
            const id = setInterval(tick, delay)
            return (): void => clearInterval(id)
        }

        return (): null => null
    }, [delay])
}
