/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect, useState } from 'react'
import './DevicesAssociation.sass'
import MultiSelect from '../Inputs/MultiSelect'
import { useAppSelector } from '../../store/reducers/store'
import Paragraph from '../Typography/Paragraph'
import { Device, EntityStatus } from '../../types/data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import UppercaseLabel from '../Typography/UppercaseLabel'
import Button from '../Buttons/Button'
import PendingDeviceModal from '../Modal/PendingDeviceModal'

interface Props {
    title: string
    currentSelection: Device[]
    onSelectionChange: (newDevices: Device[], pendingApprovated: Device[]) => void
    emptyMessage?: string
}

const DevicesAssociation: React.FC<Props> = ({ title, currentSelection, onSelectionChange, emptyMessage = '' }) => {
    const data = useAppSelector((data) => data.data)
    const [selectedDevices, setSelectedDevices] = useState<Device[]>([])
    const [pendingDevices, setPendingDevices] = useState<Device[]>([])
    const [resetDeviceSelectors, setResetDeviceSelectors] = useState(false)
    const [pendingApprovated, setPendingApprovated] = useState<Device[]>([])
    const [isFirstTime, setIsFirstTime] = useState(true)

    const [showPendingDevicesModal, setShowPendingDevicesModal] = useState(false)

    useEffect(() => {
        if (currentSelection && currentSelection.length > 0 && isFirstTime) {
            setIsFirstTime(false)
            setSelectedDevices(
                currentSelection.filter((item) => item.status === EntityStatus.ACTIVE || item.status === undefined)
            )
            setPendingDevices(currentSelection.filter((item) => item.status === EntityStatus.PENDING))
        }
    }, [currentSelection, isFirstTime])

    useEffect(() => {
        onSelectionChange(selectedDevices, pendingApprovated)
    }, [selectedDevices])

    useEffect(() => {
        if (resetDeviceSelectors) setResetDeviceSelectors(false)
    }, [resetDeviceSelectors])

    return (
        <div className="elevated-card full-width">
            {showPendingDevicesModal && (
                <PendingDeviceModal
                    devices={pendingDevices}
                    onConfirm={(devices) => {
                        setSelectedDevices([...selectedDevices, ...devices])
                        setPendingApprovated([...pendingApprovated, ...devices])
                        setPendingDevices(
                            pendingDevices.filter((device) => devices.filter((d) => d.id === device.id).length === 0)
                        )
                        setShowPendingDevicesModal(false)
                    }}
                    onClose={() => setShowPendingDevicesModal(false)}
                />
            )}
            <div className="input-form-box">
                <div className="input-form-box__three-col-row">
                    <MultiSelect
                        inputLabel={title}
                        placeholder="Seleziona uno o più dispositivi"
                        reset={resetDeviceSelectors}
                        options={data.devices
                            .map((device) => ({ value: device.id.toString(), label: device.name }))
                            // eslint-disable-next-line max-len
                            .filter(
                                (dev) =>
                                    selectedDevices.find((d) => d.id.toString() === dev.value.toString()) ===
                                        undefined &&
                                    pendingDevices.find((d) => d.id.toString() === dev.value.toString()) === undefined
                            )}
                        onValueChange={(selectedOption) => {
                            // eslint-disable-next-line max-len
                            setSelectedDevices([
                                ...selectedDevices,
                                ...data.devices.filter((dev) =>
                                    selectedOption.find((opt) => opt.value.toString() === dev.id.toString())
                                ),
                            ])
                            setResetDeviceSelectors(true)
                        }}
                        hideSelectedOptions
                        closeMenuOnSelect={false}
                    />
                    {pendingDevices.length > 0 && (
                        <div>
                            <div style={{ marginBottom: 8, display: 'flex', alignItems: 'center', gap: 4 }}>
                                <UppercaseLabel>Dispositivi in attesa di approvazione </UppercaseLabel>
                                <span className="notification-dot device-notification" />
                            </div>
                            <Button buttonType="secondary" onClick={() => setShowPendingDevicesModal(true)}>
                                Visualizza dispositivi in attesa
                            </Button>
                        </div>
                    )}
                </div>
                <div className={`chip-container-box ${selectedDevices.length > 0 ? 'not-empty-box' : 'empty-box'}`}>
                    {selectedDevices.length > 0 ? (
                        <>
                            {selectedDevices.map((device) => {
                                return (
                                    <DeviceChip
                                        key={device.id}
                                        device={device}
                                        onRemove={() => {
                                            setSelectedDevices(
                                                // eslint-disable-next-line max-len
                                                selectedDevices.filter((dev) => dev.id !== device.id)
                                            )
                                        }}
                                    />
                                )
                            })}
                        </>
                    ) : (
                        <Paragraph>{emptyMessage}</Paragraph>
                    )}
                </div>
            </div>
        </div>
    )
}

const DeviceChip: React.FC<{ device: Device; onRemove: () => void }> = ({ device, onRemove }) => {
    return (
        <div className="device-chip">
            <span className="device-chip__name">{`${device.name} ${
                device.serialNumber ? `(S.N.: ${device.serialNumber})` : ''
            } `}</span>
            <span className="device-chip__remove-icon" onClick={() => onRemove()}>
                <FontAwesomeIcon icon={faTimes} />
            </span>
        </div>
    )
}

export default DevicesAssociation
