import React, { useCallback, useEffect, useState } from 'react'
import TableHeader from '../../../../components/Table/TableHeader'
import Table, { TableColumn } from '../../../../components/Table/Table'
import SingleSelect from '../../../../components/Inputs/SingleSelect'
import { NotificationTargetEntity } from '../../../../resources/other-constants'
import { BroadcastNotification, BroadcastNotificationToSend, NotificationsTarget } from '../../../../types/data'
import TextareaInput from '../../../../components/Inputs/TextareaInput'
import Button from '../../../../components/Buttons/Button'
import TranslationModal from '../../../../components/Modal/TranslationModal'
import { useAppSelector } from '../../../../store/reducers/store'
import { sendBroadcastNotification } from '../../../../resources/api-constants'
import { ToastError, ToastSuccess } from '../../../../utility/toast'
import { truncateText } from '../../../../utility/functions'

const defaultNotificationToSend: BroadcastNotificationToSend = {
    title: '',
    target: NotificationsTarget.all,
    localized: { it: { title: '' } },
}

const SendNotificationSection: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const [notificationToSend, setNotificationToSend] = useState<BroadcastNotificationToSend>(defaultNotificationToSend)
    const [resetFlag, setResetFlag] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLocalizationVisible, setIsLocalizationVisible] = useState(false)

    const getTargetEntityString = useCallback((target: NotificationsTarget) => {
        switch (target) {
            case NotificationsTarget.dealers:
                return 'Distributori'
            case NotificationsTarget.centers:
                return 'Centri'
            case NotificationsTarget.all:
            default:
                return 'Tutti'
        }
    }, [])

    const columns: TableColumn[] = [
        {
            title: 'Messaggio',
            field: 'title',
            render: (data: BroadcastNotification) => <p>{truncateText(data.title, 100)}</p>,
        },
        {
            title: 'Visibilità',
            field: 'target',
            render: (data: BroadcastNotification) => <p>{getTargetEntityString(data.target)}</p>,
        },
        {
            title: 'Data di invio notifica',
            field: 'created_on',
            render: (data: BroadcastNotification) => <p>{new Date(data.createdOn).toLocaleDateString()}</p>,
        },
        {
            title: 'Notifiche inviate',
            field: 'successfulCount',
            sorting: false,
            render: (data: BroadcastNotification) => <p>{data.successfulCount}</p>,
        },
        {
            title: 'Errori di consegna',
            field: 'failedCount',
            sorting: false,
            render: (data: BroadcastNotification) => <p>{data.failedCount}</p>,
        },
    ]

    useEffect(() => {
        if (resetFlag) setResetFlag(false)
    }, [resetFlag])

    const sendNotification = async () => {
        try {
            setIsLoading(true)
            const sentNotification = await sendBroadcastNotification(
                user.loggedUserData?.authToken || '',
                notificationToSend
            )
            if (sentNotification) {
                ToastSuccess('Notifica inviata con successo')
                setResetFlag(true)
                setNotificationToSend(defaultNotificationToSend)
            }
        } catch (error) {
            console.error(error)
            ToastError("Errore durante l'invio della notifica")
        }
        setIsLoading(false)
    }

    return (
        <>
            {isLocalizationVisible && (
                <TranslationModal
                    modalTitle="Gestione traduzioni"
                    attributesSchema={notificationToSend.localized}
                    onClose={() => setIsLocalizationVisible(false)}
                    onConfirm={(localized) => {
                        setNotificationToSend({ ...notificationToSend, localized })
                        setIsLocalizationVisible(false)
                    }}
                />
            )}

            <div className="page-content-flow">
                <div className="elevated-card full-width">
                    <div className="page-header-section">
                        <div className="page-header-section__left-box" />
                        <div className="page-header-section__right-box">
                            <div style={{ display: 'flex', gap: 16 }}>
                                <Button
                                    disabled={isLoading || !notificationToSend.title}
                                    buttonType="secondary"
                                    onClick={() => setIsLocalizationVisible(true)}
                                    loading={isLoading}
                                >
                                    Gestisci traduzioni
                                </Button>
                                <Button
                                    disabled={
                                        !notificationToSend.title ||
                                        Object.keys(notificationToSend.localized).length === 0 ||
                                        isLoading
                                    }
                                    loading={isLoading}
                                    buttonType="primary"
                                    onClick={() => {
                                        void sendNotification()
                                    }}
                                >
                                    Invia
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="input-form-box">
                        <div className="input-form-box__three-col-row">
                            <SingleSelect
                                inputLabel="Visibilità"
                                placeholder="Seleziona uno o più tipologie di utenti"
                                options={NotificationTargetEntity}
                                value={NotificationTargetEntity.find((i) => i.value === notificationToSend.target)}
                                onValueChange={(selectedOption) =>
                                    setNotificationToSend({
                                        ...notificationToSend,
                                        target:
                                            (selectedOption?.value as NotificationsTarget) || NotificationsTarget.all,
                                    })
                                }
                            />
                        </div>
                        <div className="input-form-box__row-full-width">
                            <TextareaInput
                                inputLabel="Messaggio"
                                placeholder="Testo della notifica da tradurre in tutte le lingue di sistema"
                                value={notificationToSend.title}
                                onValueChange={(newVal) =>
                                    setNotificationToSend({
                                        ...notificationToSend,
                                        title: newVal,
                                        localized: { it: { title: newVal } },
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="elevated-card full-width">
                    <div className="relative">
                        <TableHeader positionIndex={16} />
                        <Table
                            actions={[]}
                            reloads={resetFlag}
                            columns={columns}
                            endpoint="broadcast-notifications"
                            // filterParams={finalFilters}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SendNotificationSection
