import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Table, { TableAction, TableColumn } from '../../components/Table/Table'
import TableHeader from '../../components/Table/TableHeader'
import Button from '../../components/Buttons/Button'
import AdvancedSearch from '../../components/AdvancedSearch/AdvancedSearch'
import TextInput from '../../components/Inputs/TextInput'
import MultiSelect from '../../components/Inputs/MultiSelect'
import { ROUTES } from '../../resources/routes-constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { AcceptedMedia, Device, IcooneMediaItem, MediaDirectory, MediaTarget, MediaVisibility } from '../../types/data'
import { useAppSelector } from '../../store/reducers/store'
import { ToastError, ToastSuccess } from '../../utility/toast'
import {
    deleteSingleIcooneMedia,
    deleteSingleMediaDirectory,
    getSingleMediaDirectory,
    getSubFolders,
} from '../../resources/api-constants'
import LinkComponent from '../../components/Link/Link'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink, faFolder, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { confirmFilterObject, formatItemsForSelectOptions, getElementIdFromCurrentRoute } from '../../utility/functions'
import { CircleFlag } from 'react-circle-flags'

import { IcooneMaterialScope, TargetEntity, icooneMediaPlaceholderImage } from '../../resources/other-constants'
import SingleSelect from '../../components/Inputs/SingleSelect'
import AlertModal from '../../components/Modal/AlertModal'
import FileInput from '../../components/Inputs/FileInput'
import MediaModal from '../../components/Modal/MediaModal'
import FolderDetailModal from '../../components/Modal/FolderDetailModal'

export interface SubNavigationHistoryElement {
    id: string
    name: string
}

export interface IcooneMaterialsDirectoryFilters {
    media_directory: string
    name: string
    format: string[]
    media_scope: string
    media_scope_category: string[]
    visibility: string
    media_target: string[]
    devices: string[]
}

const defaultFiltersState: IcooneMaterialsDirectoryFilters = {
    media_directory: '',
    name: '',
    format: [],
    media_scope: '',
    media_scope_category: [],
    visibility: '',
    media_target: [],
    devices: [],
}

const defaultDirectory: MediaDirectory = {
    id: '-1',
    name: '',
    mediaScope: {
        id: '',
        label: '',
    },
    visibility: MediaVisibility.all,
    creationDate: '',
    updateDate: '',
    localized: { en: { name: '' } },
}

const IcooneMaterialsFolderDetailPage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const data = useAppSelector((data) => data.data)
    const navigate = useNavigate()
    const location = useLocation()

    const [icooneDirectory, setIcooneDirectory] = useState<MediaDirectory>(defaultDirectory)

    const [showMediaModal, setShowMediaModal] = useState(false)
    const [selectedMedia, setSelectedMedia] = useState('')

    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)
    const [resetFlag, setResetFlag] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false)
    const [forceDeleteReload, setForceDeleteReload] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const currentIcooneMediaFolderId = getElementIdFromCurrentRoute(window.location.pathname)
    const [selectedIcooneMedia, setSelectedIcooneMedia] = useState<IcooneMediaItem | null>(null)
    const [filters, setFilters] = useState<IcooneMaterialsDirectoryFilters>(defaultFiltersState)
    const [initialFilters, setInitialFilters] = useState<IcooneMaterialsDirectoryFilters | undefined>(
        defaultFiltersState
    )
    const [finalFilters, setFinalFilters] = useState<IcooneMaterialsDirectoryFilters | undefined>(undefined)
    const [subFolders, setSubFolders] = useState<MediaDirectory[]>([])
    const [showFolderInfoModal, setShowFolderInfoModal] = useState(false)
    const [selectedFolder, setSelectedFolder] = useState<MediaDirectory | undefined>(undefined)
    const currentSubNavigationHistory: SubNavigationHistoryElement[] = location.state?.navigationHistoryElements ?? []

    const fetchDetail = async () => {
        if (initialFilters?.media_directory === '') return

        try {
            setIsLoading(true)
            const fetchedItem = await getSingleMediaDirectory(
                user.loggedUserData?.authToken || '',
                currentIcooneMediaFolderId
            )
            if (fetchedItem) {
                setIcooneDirectory(fetchedItem)
            }
            const subfoldersResponse = await getSubFolders(user.loggedUserData?.authToken || '', {
                parent_directory: currentIcooneMediaFolderId,
            })
            if (subfoldersResponse) {
                setSubFolders(subfoldersResponse.items)
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante il recupero dei dati!')
        }
        setIsLoading(false)
    }

    useEffect(() => {
        setInitialFilters({ ...filters, media_directory: currentIcooneMediaFolderId })
        setFinalFilters({ ...filters, media_directory: currentIcooneMediaFolderId })
    }, [currentIcooneMediaFolderId])

    useEffect(() => {
        setFinalFilters(confirmFilterObject(initialFilters))
        void fetchDetail()
    }, [initialFilters])

    useEffect(() => {
        if (resetFlag) setResetFlag(!resetFlag)
    }, [resetFlag])

    const resetSearchParams = () => {
        if (JSON.stringify(filters) === JSON.stringify(defaultFiltersState)) return
        setResetFlag(true)
        setFilters({ ...defaultFiltersState, media_directory: currentIcooneMediaFolderId })
        setFinalFilters(confirmFilterObject({ ...defaultFiltersState, media_directory: currentIcooneMediaFolderId }))
    }

    const startDeleteProcedure = (media: IcooneMediaItem) => {
        setSelectedIcooneMedia(media)
        setShowDeleteModal(true)
    }

    const startDeleteFolderProcedure = async () => {
        try {
            await deleteSingleMediaDirectory(user.loggedUserData?.authToken || '', icooneDirectory?.id || '')
            navigate(
                icooneDirectory.mediaScope?.id.toString() === IcooneMaterialScope.marketing.toString()
                    ? ROUTES.ICOONE_MARKETING_MEDIA_ROUTE
                    : ROUTES.ICOONE_TRAINING_MEDIA_ROUTE
            )
            ToastSuccess('Cartella eliminata con successo!')
        } catch {
            ToastError('Si è verificato un errore durante la procedura di eliminazione!')
        }
    }

    const endDeleteProcedure = () => {
        setSelectedIcooneMedia(null)
        setShowDeleteModal(false)
        setForceDeleteReload(true)
    }

    const removeIcooneMedia = async () => {
        try {
            const res = await deleteSingleIcooneMedia(
                user.loggedUserData?.authToken || '',
                selectedIcooneMedia?.id || ''
            )
            if (res) endDeleteProcedure()
            else ToastError('Si è verificato un errore durante la procedura di eliminazione!')
        } catch (error) {
            console.error(error)
        }
    }

    const columns: TableColumn[] = [
        {
            title: '',
            field: '',
            render: (data: IcooneMediaItem) => (
                <FileInput
                    showMediaPreview={(media) => {
                        setSelectedMedia(media)
                        setShowMediaModal(true)
                    }}
                    disableDelete
                    disableEdit
                    acceptedFormat={AcceptedMedia.ALL}
                    placeholder={icooneMediaPlaceholderImage}
                    currentFile={data?.files.find((file) => file.locale === 'en')?.url || ''}
                />
            ),
            sorting: false,
        },
        {
            title: 'Nome file',
            field: 'name',
            render: (data: IcooneMediaItem) => (
                <LinkComponent internalRoute={`${ROUTES.ICOONE_MEDIA_DETAIL_ROUTE}${data.id}`}>
                    {data.name}
                </LinkComponent>
            ),
        },
        {
            title: 'Target',
            field: 'target',
            sorting: false,
            render: (data: IcooneMediaItem) => <p>{data.targets.map((item) => item.name).join(', ')}</p>,
        },
        {
            title: 'Traduzioni',
            field: 'locale',
            sorting: false,
            render: (data: IcooneMediaItem) => (
                <p>
                    <div style={{ display: 'flex', gap: '8px' }}>
                        {data.files.length > 0 &&
                            data.files.map((item) => (
                                <CircleFlag
                                    key={item.locale}
                                    countryCode={item.locale.replace('en', 'uk')}
                                    height="25"
                                />
                            ))}
                    </div>
                </p>
            ),
        },
        {
            title: 'Ultima modifica',
            field: 'last_edit',
            sorting: false,
            render: (data: IcooneMediaItem) => <p>{new Date(data.updateDate).toLocaleString()}</p>,
        },
    ]

    const actions: TableAction[] = [
        (rowData: IcooneMediaItem) => ({
            icon: () => <FontAwesomeIcon icon={faPen} size="xs" />,
            onClick: () => navigate(`${ROUTES.ICOONE_MEDIA_DETAIL_ROUTE}${rowData.id}`),
            tooltip: 'Modifica',
        }),
        (rowData: IcooneMediaItem) => ({
            icon: () => <FontAwesomeIcon icon={faTrash} size="xs" />,
            onClick: () => startDeleteProcedure(rowData),
            tooltip: 'Elimina',
        }),
    ]

    const updatedHistory = useMemo(() => {
        return [...currentSubNavigationHistory, { name: icooneDirectory.name, id: icooneDirectory.id }]
    }, [currentSubNavigationHistory, icooneDirectory])

    const truncateHistoryToElement = useCallback(
        (id: string) => {
            const newHistory: SubNavigationHistoryElement[] = []
            currentSubNavigationHistory.forEach((element) => {
                if (element.id !== id) {
                    newHistory.push(element)
                } else return
            })
        },
        [currentSubNavigationHistory]
    )

    return (
        <>
            {showMediaModal && (
                <MediaModal media={selectedMedia} mediaType={null} onClose={() => setShowMediaModal(false)} />
            )}
            {showDeleteFolderModal && (
                <AlertModal
                    modalTitle="Elimina cartella"
                    modalMessage={`Sei sicuro di voler eliminare la cartella ${icooneDirectory.name}? Non ti sarà più possibile recuperare il suo contenuto. Questa operazione è irreversibile.`}
                    onClose={() => setShowDeleteFolderModal(false)}
                    onConfirm={() => void startDeleteFolderProcedure()}
                />
            )}
            {showDeleteModal && (
                <AlertModal
                    modalTitle="Elimina icoone media"
                    modalMessage={`Sei sicuro di voler eliminare l'elemento ${selectedIcooneMedia?.name}? Questa operazione è irreversibile.`}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => void removeIcooneMedia()}
                />
            )}
            {showFolderInfoModal && (
                <FolderDetailModal
                    mediaDirectory={selectedFolder}
                    onClose={(requestSucceded) => {
                        setFinalFilters(confirmFilterObject(initialFilters))
                        setShowFolderInfoModal(false)
                        if (requestSucceded) void fetchDetail()
                    }}
                    idMediaScope={icooneDirectory.mediaScope?.id.toString() ?? ''}
                    idParentDirectory={currentIcooneMediaFolderId}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box" />
                <div className="page-header-section__right-box">
                    <Button
                        customClassName={`${showAdvancedSearch ? 'active' : ''}`}
                        buttonType="ghost"
                        onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                    >
                        Ricerca avanzata
                    </Button>
                    <Button
                        disabled={isLoading}
                        buttonType="secondary-error"
                        onClick={() => setShowDeleteFolderModal(true)}
                    >
                        Elimina cartella
                    </Button>

                    <Button disabled={isLoading} buttonType="primary" onClick={() => setShowFolderInfoModal(true)}>
                        Crea sottocartella
                    </Button>
                    <Button
                        disabled={isLoading}
                        buttonType="primary"
                        onClick={() =>
                            navigate(`${ROUTES.ICOONE_MEDIA_DETAIL_ROUTE}new`, {
                                state: {
                                    scope: currentIcooneMediaFolderId,
                                },
                            })
                        }
                    >
                        Aggiungi materiale
                    </Button>
                </div>
            </div>
            <div className="page-content-block">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                    }}
                >
                    <span className="navigation-paths">
                        <Link
                            style={{ marginRight: currentSubNavigationHistory.length > 0 ? 0 : 4 }}
                            to={
                                icooneDirectory.mediaScope?.id.toString() === IcooneMaterialScope.marketing.toString()
                                    ? ROUTES.ICOONE_MARKETING_MEDIA_ROUTE
                                    : ROUTES.ICOONE_TRAINING_MEDIA_ROUTE
                            }
                        >
                            {icooneDirectory.mediaScope?.label} Materials
                        </Link>
                        {currentSubNavigationHistory.length > 0 && (
                            <div style={{ marginRight: 4 }}>
                                {currentSubNavigationHistory.map((subNavigationElement) => (
                                    <>
                                        <label style={{ margin: '0 4px' }}>/</label>
                                        <span
                                            className="navigation-sub-element"
                                            key={subNavigationElement.id}
                                            onClick={() =>
                                                navigate(
                                                    `${ROUTES.ICOONE_MEDIA_FOLDER_DETAIL_ROUTE}${subNavigationElement.id}`,
                                                    {
                                                        state: {
                                                            navigationHistoryElements: truncateHistoryToElement(
                                                                subNavigationElement.id
                                                            ),
                                                        },
                                                    }
                                                )
                                            }
                                        >
                                            {subNavigationElement.name}
                                        </span>
                                    </>
                                ))}
                            </div>
                        )}{' '}
                        / {currentIcooneMediaFolderId === '-1' ? 'Nuova cartella' : icooneDirectory.name || ''}
                    </span>
                </div>
                <AdvancedSearch
                    isVisible={showAdvancedSearch}
                    onReset={() => resetSearchParams()}
                    onSearch={() =>
                        setFinalFilters(
                            confirmFilterObject({ ...filters, media_directory: currentIcooneMediaFolderId })
                        )
                    }
                >
                    <div className="input-form-box__three-col-row">
                        <TextInput
                            inputLabel="Nome file"
                            value={filters.name}
                            onValueChange={(newVal) => setFilters({ ...filters, name: newVal })}
                        />
                        <SingleSelect
                            inputLabel="Visibilità"
                            placeholder="Seleziona uno o più tipologie di utenti"
                            options={TargetEntity}
                            reset={resetFlag}
                            value={TargetEntity.find((i) => i.value === filters.visibility)}
                            onValueChange={(selectedOption) =>
                                setFilters({
                                    ...filters,
                                    visibility: selectedOption?.value || '',
                                })
                            }
                        />
                        <MultiSelect
                            inputLabel="Target"
                            placeholder="Seleziona uno o più target"
                            options={data.mediaTargets.map((item) => ({ value: item.id, label: item.name }))}
                            reset={resetFlag}
                            values={formatItemsForSelectOptions<MediaTarget>(
                                data.mediaTargets.filter((item) => filters.media_target.indexOf(item.id) !== -1)
                            )}
                            onValueChange={(selectedOption) =>
                                setFilters({
                                    ...filters,
                                    media_target: selectedOption.map((opt) => opt.value) || [],
                                })
                            }
                        />
                        <MultiSelect
                            inputLabel="Dispositivi"
                            placeholder="Seleziona uno o più dispositivi"
                            options={data.devices.map((device) => ({
                                value: device.id.toString(),
                                label: device.name,
                            }))}
                            reset={resetFlag}
                            values={formatItemsForSelectOptions<Device>(
                                data.devices.filter((device) => filters.devices.indexOf(device.id.toString()) !== -1)
                            )}
                            onValueChange={(selectedOption) =>
                                setFilters({
                                    ...filters,
                                    devices: selectedOption.map((opt) => opt.value) || [],
                                })
                            }
                        />
                    </div>
                </AdvancedSearch>
                <div className="sub-folders-list">
                    {subFolders.map((folder) => (
                        <div key={folder.id} className="sub-folders-list__item">
                            <FontAwesomeIcon icon={faFolder} style={{ opacity: 0.4 }} />
                            <span>{folder.localized.it.name}</span>
                            <div style={{ marginLeft: 16, display: 'flex', flexDirection: 'row', gap: 8 }}>
                                <div
                                    className="sub-folders-list-item-action"
                                    onClick={() => {
                                        setSelectedFolder(folder)
                                        setShowFolderInfoModal(true)
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPen} color="#000" />
                                </div>
                                <div
                                    className="sub-folders-list-item-action"
                                    onClick={() =>
                                        navigate(`${ROUTES.ICOONE_MEDIA_FOLDER_DETAIL_ROUTE}${folder.id}`, {
                                            state: {
                                                navigationHistoryElements: updatedHistory,
                                            },
                                        })
                                    }
                                >
                                    <FontAwesomeIcon icon={faExternalLink} color="#000" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="relative">
                    {finalFilters?.media_directory && (
                        <>
                            <TableHeader positionIndex={23} />
                            <Table
                                actions={actions}
                                columns={columns}
                                filterParams={finalFilters}
                                endpoint="media-files"
                                forceDeleteReload={forceDeleteReload}
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default IcooneMaterialsFolderDetailPage
