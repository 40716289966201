import React, { useEffect, useState } from 'react'
import { getResultMediaOfBodyPart } from '../resources/api-constants'
import { useAppSelector } from '../store/reducers/store'
import { AcceptedMedia, BodyPart, ResultMedia } from '../types/data'
import FileInput from '../components/Inputs/FileInput'
import UppercaseLabel from '../components/Typography/UppercaseLabel'
import Button from '../components/Buttons/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import MediaModal from '../components/Modal/MediaModal'

const badgePlaceholderImage = 'https://icoone-media.s3.eu-west-1.amazonaws.com/defaults/badge_avatar_placeholder.png'

export const ResultMediaSection: React.FC<{
    idResult: string
    bodyPart: BodyPart
    index: number
    canEdit?: boolean
    canDelete?: boolean
    selectedBodyPartCoverId: number | null
    onRawMediaChange?: (newArray: any[]) => void
    onRawMediaDelete?: (newArray: ResultMedia[]) => void
    onSelectedBodyPartCoverIdChange?: (id: number | null) => void
}> = ({
    idResult,
    bodyPart,
    index,
    canDelete,
    canEdit,
    selectedBodyPartCoverId,
    onRawMediaChange,
    onRawMediaDelete,
    onSelectedBodyPartCoverIdChange,
}) => {
    const user = useAppSelector((data) => data.user)
    const [rawMediaArray, setRawMediaArray] = useState<any[]>(['', ''])
    const [showMediaModal, setShowMediaModal] = useState(false)
    const [selectedMedia, setSelectedMedia] = useState('')

    const emptyBodyPartMedia: ResultMedia = {
        idResult: parseInt(idResult, 10),
        url: '',
        idBodyPart: parseInt(bodyPart.id, 10),
        sessionDate: '',
        creationDate: '',
        updateDate: '',
        sessionIndex: 0,
    }

    const [currentResultMedia, setCurrentResultMedia] = useState<ResultMedia[]>([
        emptyBodyPartMedia,
        { ...emptyBodyPartMedia, sessionIndex: 1 },
    ])

    const getResultBodyPartMedias = async () => {
        try {
            const resultMedia = await getResultMediaOfBodyPart(
                user.loggedUserData?.authToken || '',
                idResult,
                bodyPart.id
            )
            if (resultMedia && resultMedia.length === 2) {
                const firstMedia = resultMedia[0] ?? {
                    ...emptyBodyPartMedia,
                }
                const lastMedia = resultMedia[1] ?? {
                    ...emptyBodyPartMedia,
                }
                setCurrentResultMedia([firstMedia, lastMedia])
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (idResult.toString() !== '-1') {
            void getResultBodyPartMedias()
        }
    }, [idResult])

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 16,
                borderLeft: index !== 0 ? '2px dashed rgba(0,0,0,.07)' : '',
                paddingLeft: index !== 0 ? 16 : '',
            }}
        >
            {showMediaModal && (
                <MediaModal media={selectedMedia} mediaType={null} onClose={() => setShowMediaModal(false)} />
            )}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <img src={bodyPart.image} alt={bodyPart.name} style={{ height: 50, width: 50 }} />

                <span style={{ textAlign: 'center', margin: '8px 0' }}>{bodyPart.name}</span>
                <Button
                    buttonType="ghost"
                    style={{
                        backgroundColor: selectedBodyPartCoverId === parseInt(bodyPart.id, 10) ? '#7493b2' : '',
                        color: selectedBodyPartCoverId === parseInt(bodyPart.id, 10) ? 'white' : '',
                    }}
                    onClick={() => {
                        if (onSelectedBodyPartCoverIdChange) {
                            if (selectedBodyPartCoverId === parseInt(bodyPart.id, 10)) {
                                onSelectedBodyPartCoverIdChange(null)
                            } else {
                                onSelectedBodyPartCoverIdChange(parseInt(bodyPart.id, 10))
                            }
                        }
                    }}
                >
                    {selectedBodyPartCoverId === parseInt(bodyPart.id, 10)
                        ? 'Copertina attuale'
                        : 'Imposta come copertina'}
                </Button>
                {canDelete && (
                    <Button
                        buttonType="ghost"
                        onClick={() => {
                            if (onRawMediaDelete) onRawMediaDelete(currentResultMedia)
                        }}
                    >
                        <FontAwesomeIcon icon={faTrash} color="red" />
                    </Button>
                )}
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    gap: 16,
                }}
            >
                {currentResultMedia.map((currentMedia, i) => (
                    <div key={currentMedia.id} style={{ display: 'flex', gap: 16, flexDirection: 'column' }}>
                        <UppercaseLabel>{i === 0 ? 'Prima' : 'dopo'}</UppercaseLabel>
                        <FileInput
                            disableEdit={!canEdit}
                            disableDelete={!canDelete || idResult !== '-1'}
                            currentFile={currentMedia.url}
                            placeholder={badgePlaceholderImage}
                            acceptedFormat={AcceptedMedia.IMAGE}
                            showMediaPreview={(media) => {
                                setSelectedMedia(media)
                                setShowMediaModal(true)
                            }}
                            onFileChange={(file) => {
                                const newArray = rawMediaArray.map((rawMedia, j) => {
                                    if (i === j) return { file, idBodyPart: bodyPart.id }
                                    return rawMedia
                                })
                                setRawMediaArray(newArray)
                                if (onRawMediaChange) onRawMediaChange(newArray)
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ResultMediaSection
