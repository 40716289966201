import React, { ReactNode, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from './pages/HomePage'
import NotFoundPage from './pages/NotFoundPage'
import { ROUTES } from './resources/routes-constants'
import './styles/main.sass'
import LoginPage from './pages/authPages/LoginPage'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { frontendBaseUrl } from './resources/api-constants'
import Sidebar from './components/Sidebar/Sidebar'
import Navbar from './components/Navbar/Navbar'
import ProtocolsPage from './pages/Protocols/ProtocolsPage'
import CentersPage from './pages/Centers/CentersPage'
import ProtocolDetailPage from './pages/Protocols/ProtocolDetailPage'
import CenterDetailPage from './pages/Centers/CenterDetailPage'
import { useAppDispatch, useAppSelector } from './store/reducers/store'
import DealersPage from './pages/Dealers/DealersPage'
import DealersDetailPage from './pages/Dealers/DealersDetailPage'
import { fetchGeneralData, getNotifications } from './store/actions/thunkActions'
import RequestResetPassPage from './pages/authPages/RequestResetPassPage'
import ResetPasswordPage from './pages/authPages/ResetPassPage'
import ResetPassView from './pages/authPages/ResetPassView'
import { setLoggedUserData } from './store/actions/user'
import ResultsPage from './pages/Results/ResultsPage'
import Analytics from './pages/Analytics'
import BadgesPage from './pages/Configurations/Badges/BadgesPage'
import CertificationsPage from './pages/Configurations/Certifications/CertificationsPage'
import DevicesPage from './pages/Configurations/Devices/DevicesPage'
import CommercialAreasPage from './pages/Configurations/CommercialAreas/CommercialAreasPage'
import ResultsDetailPage from './pages/Results/ResultsDetailPage'
import DeviceDetailPage from './pages/Configurations/Devices/DeviceDetailPage'
import BadgeDetailPage from './pages/Configurations/Badges/BadgeDetailPage'
import CertificationDetailPage from './pages/Configurations/Certifications/CertificationDetailPage'
import IcooneMaterialsFoldersPage from './pages/IcooneMedia/IcooneMaterialsFoldersPage'
import { IcooneMaterialScope } from './resources/other-constants'
import IcooneMediaDetailPage from './pages/IcooneMedia/IcooneMediaDetailPage'
import IcooneStartPage from './pages/Configurations/IcooneStart/IcooneStartPage'
import IcooneMaterialsFolderDetailPage from './pages/IcooneMedia/IcooneMaterialsFolderDetailPage'
import FAQDetailPage from './pages/faq/FaqDetailPage'
import FaqsPage from './pages/faq/FaqsPage'
import SerialsPage from './pages/Configurations/Serials/SerialPage'
import NotificationsPage from './pages/Configurations/Notifications/NotificationsPage'

const RootComponent: React.FC = () => {
    const user = useAppSelector((appData) => appData.user)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (user.loggedUserData && user.loggedUserData.authToken) {
            void dispatch(fetchGeneralData(user.loggedUserData?.authToken))
        }

        setInterval(() => {
            void dispatch(getNotifications(user.loggedUserData?.authToken || ''))
        }, 5000)
    }, [user.loggedUserData])

    return (
        <Router basename={frontendBaseUrl}>
            <ToastContainer />
            {user.loggedUserData ? (
                <>
                    {user.loggedUserData?.isFirstAccess ? (
                        <ResetPassView
                            onPasswordChange={() =>
                                user.loggedUserData &&
                                dispatch(setLoggedUserData({ ...user.loggedUserData, isFirstAccess: false }))
                            }
                        />
                    ) : (
                        <>
                            <Navbar />
                            <Sidebar />

                            <Routes>
                                <Route
                                    path="*"
                                    element={
                                        <InternalPageContainer>
                                            <NotFoundPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={ROUTES.HOMEPAGE_ROUTE}
                                    element={
                                        <InternalPageContainer>
                                            <HomePage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={ROUTES.CENTERS_ROUTE}
                                    element={
                                        <InternalPageContainer>
                                            <CentersPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={`${ROUTES.CENTER_DETAIL_ROUTE}:id`}
                                    element={
                                        <InternalPageContainer>
                                            <CenterDetailPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={ROUTES.DEALERS_ROUTE}
                                    element={
                                        <InternalPageContainer>
                                            <DealersPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={`${ROUTES.DEALER_DETAIL_ROUTE}:id`}
                                    element={
                                        <InternalPageContainer>
                                            <DealersDetailPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={ROUTES.PROTOCOLS_ROUTE}
                                    element={
                                        <InternalPageContainer>
                                            <ProtocolsPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={`${ROUTES.PROTOCOL_DETAIL_ROUTE}:id`}
                                    element={
                                        <InternalPageContainer>
                                            <ProtocolDetailPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={ROUTES.RESULTS_ROUTE}
                                    element={
                                        <InternalPageContainer>
                                            <ResultsPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={`${ROUTES.RESULT_DETAIL_ROUTE}:id`}
                                    element={
                                        <InternalPageContainer>
                                            <ResultsDetailPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={ROUTES.ICOONE_TRAINING_MEDIA_ROUTE}
                                    element={
                                        <InternalPageContainer>
                                            <IcooneMaterialsFoldersPage
                                                icooneMaterialScope={IcooneMaterialScope.training}
                                            />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={ROUTES.ICOONE_MARKETING_MEDIA_ROUTE}
                                    element={
                                        <InternalPageContainer>
                                            <IcooneMaterialsFoldersPage
                                                icooneMaterialScope={IcooneMaterialScope.marketing}
                                            />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={ROUTES.ICOONE_AFTER_SALES_MEDIA_ROUTE}
                                    element={
                                        <InternalPageContainer>
                                            <IcooneMaterialsFoldersPage
                                                icooneMaterialScope={IcooneMaterialScope.afterSales}
                                            />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={`${ROUTES.ICOONE_MEDIA_DETAIL_ROUTE}:id`}
                                    element={
                                        <InternalPageContainer>
                                            <IcooneMediaDetailPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={`${ROUTES.ICOONE_MEDIA_FOLDER_DETAIL_ROUTE}:id`}
                                    element={
                                        <InternalPageContainer>
                                            <IcooneMaterialsFolderDetailPage />
                                        </InternalPageContainer>
                                    }
                                />

                                <Route
                                    path={ROUTES.ANALYTICS_ROUTE}
                                    element={
                                        <InternalPageContainer>
                                            <Analytics />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={ROUTES.BADGES_ROUTE}
                                    element={
                                        <InternalPageContainer>
                                            <BadgesPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={`${ROUTES.BADGE_DETAIL_ROUTE}:id`}
                                    element={
                                        <InternalPageContainer>
                                            <BadgeDetailPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={ROUTES.CERTIFICATIONS_ROUTE}
                                    element={
                                        <InternalPageContainer>
                                            <CertificationsPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={`${ROUTES.CERTIFICATION_DETAIL_ROUTE}:id`}
                                    element={
                                        <InternalPageContainer>
                                            <CertificationDetailPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={ROUTES.DEVICES_ROUTE}
                                    element={
                                        <InternalPageContainer>
                                            <DevicesPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={`${ROUTES.DEVICE_DETAIL_ROUTE}:id`}
                                    element={
                                        <InternalPageContainer>
                                            <DeviceDetailPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={ROUTES.COMMERCIAL_AREA_ROUTE}
                                    element={
                                        <InternalPageContainer>
                                            <CommercialAreasPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={ROUTES.ICOONE_START_ROUTE}
                                    element={
                                        <InternalPageContainer>
                                            <IcooneStartPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={`${ROUTES.FAQS_ROUTE}`}
                                    element={
                                        <InternalPageContainer>
                                            <FaqsPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={`${ROUTES.FAQ_DETAIL_ROUTE}:id`}
                                    element={
                                        <InternalPageContainer>
                                            <FAQDetailPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={`${ROUTES.SERIALS_ROUTE}`}
                                    element={
                                        <InternalPageContainer>
                                            <SerialsPage />
                                        </InternalPageContainer>
                                    }
                                />
                                <Route
                                    path={`${ROUTES.NOTIFICATIONS_ROUTE}`}
                                    element={
                                        <InternalPageContainer>
                                            <NotificationsPage />
                                        </InternalPageContainer>
                                    }
                                />
                            </Routes>
                        </>
                    )}
                </>
            ) : (
                <Routes>
                    <Route path={ROUTES.LOGIN_ROUTE} element={<LoginPage />} />
                    <Route path={ROUTES.REQUEST_RESET_PASSWORD_ROUTE} element={<RequestResetPassPage />} />
                    <Route path={ROUTES.RESET_PASSWORD_ROUTE} element={<ResetPasswordPage />} />
                </Routes>
            )}
        </Router>
    )
}

const InternalPageContainer: React.FC<{ children?: ReactNode }> = ({ children }) => {
    const graphic = useAppSelector((data: { graphic: any }) => data.graphic)

    return (
        <div className={`main-page-container ${graphic.sidebarIsCollapsed ? 'expanded-page-container' : ''}`}>
            {children}
        </div>
    )
}

export default RootComponent
