import React, { useEffect, useState } from 'react'
import Table, { TableAction, TableColumn } from '../../../components/Table/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faCheckCircle, faMailForward, faTrash } from '@fortawesome/free-solid-svg-icons'
import TableHeader from '../../../components/Table/TableHeader'
import { ToastError, ToastSuccess } from '../../../utility/toast'
import Button from '../../../components/Buttons/Button'
import CreateUserModal from '../../../components/Modal/CreateUserModal'
import { useAppSelector } from '../../../store/reducers/store'
import { deleteUser, getEntityUsers, sendUserCredentials } from '../../../resources/api-constants'
import AlertModal from '../../../components/Modal/AlertModal'
import TextLabel from '../../../components/Badges/TextLabel'
import { User } from '../../../types/data'

const DealerUsers: React.FC<{ dealerId: string }> = ({ dealerId }) => {
    const user = useAppSelector((data) => data.user)
    const { userRoles } = useAppSelector((data) => data.data)
    const [resetFlag, setResetFlag] = useState(false)
    const [showUsersModal, setShowUsersModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selecteUser, setSelectedUser] = useState<User | null>(null)
    const [dealerUsers, setDealerUsers] = useState<User[]>([])

    const fetchDealerUsers = async () => {
        try {
            const fetchedUsers = await getEntityUsers(
                user.loggedUserData?.authToken || '',
                'dealer',
                parseInt(dealerId, 10)
            )
            if (fetchedUsers && fetchedUsers.length > 0) setDealerUsers(fetchedUsers)
        } catch (error) {
            console.error(error)
            ToastError('Errore durante il recupero degli utenti del centro')
        }
    }

    const deleteSubUser = async () => {
        try {
            if (!selecteUser) return
            await deleteUser(user.loggedUserData?.authToken || '', selecteUser?.id ?? -1)
            ToastSuccess('Utente eliminato con successo')
            setShowDeleteModal(false)
            setSelectedUser(null)
            void fetchDealerUsers()
        } catch (error) {
            console.error(error)
            ToastError("Errore durante l'eliminazione dell'utente")
        }
    }

    const startSendCredentialsProcedure = async (subUser: User) => {
        try {
            await sendUserCredentials(user.loggedUserData?.authToken || '', subUser.id.toString())
            ToastSuccess('Credenziali inviate con successo')
        } catch (error) {
            console.error(error)
            ToastError("Si è verificato un errore durante l'invio delle credenziali!")
        }
    }

    useEffect(() => {
        void fetchDealerUsers()
    }, [])

    useEffect(() => {
        if (resetFlag) setResetFlag(!resetFlag)
    }, [resetFlag])

    const columns: TableColumn[] = [
        {
            title: 'Nome',
            field: 'name',
            sorting: false,
            render: (data: User) => (
                <p>
                    {data.firstName} {data.lastName}
                </p>
            ),
        },
        {
            title: 'Email',
            field: 'email',
            sorting: false,
            render: (data: User) => (
                <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
                    {data.emailConfirmed && <FontAwesomeIcon icon={faCheckCircle} color="green" />}
                    {data.email}
                </div>
            ),
        },
        {
            title: 'Ruolo',
            field: 'role',
            sorting: false,
            render: (data: User) => (
                <TextLabel customColor={[2, 3].includes(data.role.id) ? '#8AFDEA' : '#eee'}>
                    {userRoles.find((role) => role.id === data.role.id)?.name ?? ''}
                </TextLabel>
            ),
        },
    ]

    const actions: TableAction[] = [
        (rowData: User) => ({
            icon: () => <FontAwesomeIcon icon={faMailForward} size="xs" />,
            onClick: () => {
                void startSendCredentialsProcedure(rowData)
            },
            tooltip: 'Invia credenziali',
        }),
        (rowData: User) => ({
            icon: () => <FontAwesomeIcon icon={faPen} size="xs" />,
            onClick: () => {
                setSelectedUser(rowData)
                setShowUsersModal(true)
            },
            tooltip: 'Modifica',
        }),
        (rowData: User) => ({
            icon: () => (![2, 3].includes(rowData.role.id) ? <FontAwesomeIcon icon={faTrash} size="xs" /> : <></>),
            onClick: () => {
                if (![2, 3].includes(rowData.role.id)) {
                    setSelectedUser(rowData)
                    setShowDeleteModal(true)
                }
            },
            tooltip: ![2, 3].includes(rowData.role.id) ? 'Elimina' : '',
        }),
    ]

    return (
        <>
            {showUsersModal && (
                <CreateUserModal
                    user={selecteUser ? { ...selecteUser, idRole: selecteUser.role.id } : null}
                    entityId={parseInt(dealerId, 10)}
                    onClose={() => {
                        setShowUsersModal(false)
                        setSelectedUser(null)
                    }}
                    onConfirm={() => {
                        setShowUsersModal(false)
                        setSelectedUser(null)
                        void fetchDealerUsers()
                    }}
                />
            )}
            {showDeleteModal && (
                <AlertModal
                    modalTitle="Elimina utente"
                    modalMessage={`Sei sicuro di voler eliminare l'utente ${selecteUser?.firstName} ${selecteUser?.lastName}? Questa operazione è irreversibile.`}
                    onClose={() => {
                        setShowDeleteModal(false)
                        setSelectedUser(null)
                    }}
                    onConfirm={() => void deleteSubUser()}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box" />
                <div className="page-header-section__right-box">
                    <Button buttonType="primary" onClick={() => setShowUsersModal(true)}>
                        Aggiungi utente
                    </Button>
                </div>
            </div>
            <div className="page-content-block">
                <div className="relative">
                    <TableHeader positionIndex={23} />
                    <Table actions={actions} reloads={resetFlag} columns={columns} items={dealerUsers} />
                </div>
            </div>
        </>
    )
}

export default DealerUsers
