import React, { useEffect, useMemo } from 'react'
import UppercaseLabel from '../../../../components/Typography/UppercaseLabel'
import FixedMeasureInput from '../../../../components/Inputs/FixedMeasureInput'
import Button from '../../../../components/Buttons/Button'
import { BroadcastNotificationToSend } from '../../../../types/data'
import TextareaInput from '../../../../components/Inputs/TextareaInput'
import { ToastError, ToastSuccess } from '../../../../utility/toast'
import Paragraph from '../../../../components/Typography/Paragraph'
import { useAppSelector } from '../../../../store/reducers/store'
import { getSystemInformation, updateSystemInformation } from '../../../../resources/api-constants'

interface CustomerRecallNotification extends Pick<BroadcastNotificationToSend, 'title'> {
    delay: string
}

const keywords = ['$$customer$$', '$$goals$$']
const systemInformationsKeywords = ['treatment_recall_notification_message', 'treatment_recall_notification_time']

const CustomersRecallSettings: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const [validityValueError, setValidityValueError] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [customerRecallNotification, setCustomerRecallNotification] = React.useState<CustomerRecallNotification>({
        title: '',
        delay: '',
    })

    const fetchCustomerRecallNotification = async () => {
        try {
            const notificationMessage = await getSystemInformation(
                user.loggedUserData?.authToken ?? '',
                systemInformationsKeywords[0]
            )
            const notificationTime = await getSystemInformation(
                user.loggedUserData?.authToken ?? '',
                systemInformationsKeywords[1]
            )
            if (notificationMessage && notificationTime) {
                setCustomerRecallNotification({ title: notificationMessage.value, delay: notificationTime.value })
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        void fetchCustomerRecallNotification()
    }, [])

    const saveCustomerRecallNotification = async () => {
        try {
            setIsLoading(true)
            const titleRes = await updateSystemInformation(
                user.loggedUserData?.authToken ?? '',
                systemInformationsKeywords[0],
                customerRecallNotification.title
            )
            const delayRes = await updateSystemInformation(
                user.loggedUserData?.authToken ?? '',
                systemInformationsKeywords[1],
                customerRecallNotification.delay
            )
            if (titleRes && delayRes) {
                ToastSuccess('Impostazioni salvate con successo')
            }
        } catch (error) {
            console.error(error)
            ToastError('Errore durante il salvataggio')
        }
        setIsLoading(false)
    }

    const messageIsNotValid = useMemo(() => {
        if (!customerRecallNotification.title) return true
        return (
            !!customerRecallNotification.title &&
            keywords.some((keyword) => !customerRecallNotification.title.includes(keyword))
        )
    }, [customerRecallNotification.title])

    return (
        <>
            <div className="page-content-flow">
                <div className="elevated-card full-width">
                    <div className="page-header-section">
                        <div className="page-header-section__left-box">
                            <div className="text-paragraphs-container" style={{ marginRight: 16 }}>
                                <Paragraph>
                                    Gestisci la notifica di richiamo per i clienti. Il messaggio verrà inviato
                                    automaticamente ai centri interessati dopo il periodo di tempo impostato.
                                </Paragraph>
                                <Paragraph>
                                    Ricordati di utilizzare <strong>$$customer$$</strong> per gestire dinamicamente il
                                    nome del cliente e <strong>$$goals$$</strong> per gestire dinamicamente gli
                                    obiettivi del trattamento.
                                </Paragraph>
                            </div>
                        </div>
                        <div className="page-header-section__right-box">
                            <div style={{ display: 'flex', gap: 16 }}>
                                <Button
                                    disabled={messageIsNotValid || validityValueError || isLoading}
                                    loading={isLoading}
                                    buttonType="primary"
                                    onClick={() => {
                                        void saveCustomerRecallNotification()
                                    }}
                                >
                                    Salva
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="input-form-box">
                        <div className="input-form-box__three-col-row">
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                                <UppercaseLabel>validità</UppercaseLabel>
                                <FixedMeasureInput
                                    validationCondition={/^[1-9]\d*$/}
                                    valuePostfix="mesi"
                                    type="number"
                                    step={1}
                                    min={1}
                                    value={customerRecallNotification?.delay || ''}
                                    onValueChange={(newVal) =>
                                        setCustomerRecallNotification({ ...customerRecallNotification, delay: newVal })
                                    }
                                    onInputError={(isError) => setValidityValueError(isError)}
                                />
                            </div>
                        </div>
                        <div className="input-form-box__row-full-width">
                            <TextareaInput
                                inputLabel="Messaggio"
                                placeholder="Testo della notifica da tradurre in tutte le lingue di sistema"
                                value={customerRecallNotification.title}
                                onValueChange={(newVal) =>
                                    setCustomerRecallNotification({
                                        ...customerRecallNotification,
                                        title: newVal,
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomersRecallSettings
